var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('Dialog',{attrs:{"close":_vm.closeTrigger,"closeable":!_vm.loadingFiles,"width":"550","title":!_vm.violations
      ? _vm.$t('tools.project.log.dialog.download.title.log')
      : _vm.$t('tools.project.log.dialog.download.title.violations')},on:{"close":function($event){return _vm.closeDialog()}},scopedSlots:_vm._u([{key:"button",fn:function(ref){
      var on = ref.on;
      var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({},'div',attrs,false),on),[_c('IconButton',{attrs:{"icon":"mdi-file-download"}})],1)]}},{key:"content",fn:function(){return [_c('div',{staticClass:"pb-2"},[_c('DateRangeInput',{style:(_vm.loadingFiles
            ? 'pointer-events: none!important; cursor: default!important; opacity: .5'
            : ''),attrs:{"loading":false,"disabled":false,"dateRange":_vm.dateRange},on:{"update":function($event){_vm.dateRange = $event}}}),_c('v-row',{staticClass:"ma-0 mt-2 ml-2 mr-2",style:(_vm.loadingFiles
            ? 'pointer-events: none!important; cursor: default!important; opacity: .5'
            : ''),attrs:{"align":"center"}},[_c('div',{staticClass:"file-selector",class:_vm.exportFiles.table ? ' primary--text' : '',style:(_vm.exportFiles.table
              ? 'background-color: rgba(210, 210, 210, 1)'
              : ''),on:{"click":function($event){_vm.exportFiles.table = !_vm.exportFiles.table}}},[_c('v-row',{staticClass:"ma-0",attrs:{"align":"center"}},[_c('v-icon',{staticClass:"mr-1",attrs:{"color":_vm.exportFiles.table ? 'primary' : '',"size":"20"}},[_vm._v(" mdi-microsoft-excel")]),_c('span',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(_vm.$t('tools.project.log.dialog.download.type.spreadsheet')))]),_c('span',{staticClass:"ml-1"},[_vm._v("(.xlsx)")])],1)],1),_c('v-spacer'),_c('div',{staticClass:"file-selector ml-4",class:_vm.exportFiles.protocol ? 'primary--text' : '',style:(_vm.exportFiles.protocol
              ? 'background-color: rgba(210, 210, 210, 1)'
              : ''),on:{"click":function($event){_vm.exportFiles.protocol = !_vm.exportFiles.protocol}}},[_c('v-row',{staticClass:"ma-0",attrs:{"align":"center"}},[_c('v-icon',{staticClass:"mr-1",attrs:{"color":_vm.exportFiles.protocol ? 'primary' : '',"size":"20"}},[_vm._v(" mdi-file-pdf-box")]),_c('span',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(_vm.$t('tools.project.log.dialog.download.type.protocol')))]),_c('span',{staticClass:"ml-1"},[_vm._v("(.pdf)")])],1)],1),_c('v-spacer'),_c('div',{staticClass:"file-selector ml-4",class:_vm.exportFiles.json ? 'primary--text' : '',style:(_vm.exportFiles.json ? 'background-color: rgba(210, 210, 210, 1)' : ''),on:{"click":function($event){_vm.exportFiles.json = !_vm.exportFiles.json}}},[_c('v-row',{staticClass:"ma-0",attrs:{"align":"center"}},[_c('v-icon',{staticClass:"mr-1",attrs:{"color":_vm.exportFiles.json ? 'primary' : '',"size":"20"}},[_vm._v("mdi-code-braces-box ")]),_c('span',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(_vm.$t('tools.project.log.dialog.download.type.json'))+" ")]),_c('span',{staticClass:"ml-1"},[_vm._v("(.json)")])],1)],1)],1),_c('div',{staticClass:"mb-0 mt-0 pt-1 pr-2 pl-2",staticStyle:{"position":"relative","padding-left":"3px","padding-right":"3px","width":"100%"},style:(_vm.loadingFiles
            ? 'pointer-events: none!important; cursor: default!important; opacity: .5'
            : '')},[_c('div',{staticClass:"mt-2",staticStyle:{"background-color":"#f8f8f8","border":"1px solid #f0f0f0","border-radius":"10px","overflow":"hidden !important"},style:(_vm.expandFilterSettings
              ? ("height: " + (_vm.violations == true ? 255 : 330) + "px; transition: all 150ms linear;")
              : 'height: 45px; transition: all 150ms linear')},[_c('div',{staticClass:"filter-settings"},[_c('v-row',{staticClass:"ma-0 ml-4",staticStyle:{"padding-top":"4px","padding-right":"5px"},attrs:{"align":"center"}},[_c('b',{staticStyle:{"font-size":"16px"}},[_vm._v(_vm._s(_vm.$t('tools.project.log.dialog.download.filterTitle')))]),_c('v-spacer'),_c('IconButton',{staticClass:"mr-2",style:(_vm.expandFilterSettings ? 'transform: rotate(180deg)' : ''),attrs:{"icon":"mdi-chevron-down"},on:{"click":function($event){_vm.expandFilterSettings = !_vm.expandFilterSettings}}})],1)],1),_c('div',{staticStyle:{"width":"calc(100% - 30px)","margin-left":"15px"}},[_c('TextInput',{staticClass:"mt-2 mb-2",attrs:{"value":_vm.licensePlateSearch,"placeholder":_vm.$t('tools.project.log.dialog.download.filterSearch')},on:{"change":function($event){_vm.licensePlateSearch = $event}}}),_c('FilterSelection',{key:_vm.updateData,attrs:{"violations":_vm.violations,"filter":_vm.filterHandle,"whitelistData":_vm.whitelistData},on:{"update":function($event){_vm.filterHandle = $event}}})],1)]),_c('div',{key:_vm.includeImages,style:(_vm.exportFiles.protocol != true
              ? 'opacity: .3; pointer-events: none!important'
              : '')},[_c('Checkbox',{attrs:{"src":_vm.includeImages,"text":_vm.$t('tools.project.log.dialog.download.embedImages')},on:{"update":function($event){_vm.includeImages = $event}}}),(_vm.includeImages == true)?_c('v-alert',{staticClass:"mt-3",staticStyle:{"font-size":"14px","color":"rgba(0, 0, 0, 0.5) !important"},attrs:{"text":"","filled":"","dense":"","color":"grey"}},[_vm._v(" "+_vm._s(_vm.$t('tools.project.log.dialog.download.hintImages'))+" ")]):_vm._e()],1)])],1)]},proxy:true},{key:"actions",fn:function(){return [_c('v-row',{staticClass:"ma-0 mt-3",attrs:{"align":"center"}},[(_vm.noDataResp == true)?_c('div',[_c('v-row',{staticClass:"ma-0 ml-2",attrs:{"align":"center"}},[_c('v-icon',{staticClass:"mr-2",attrs:{"color":"grey","size":"18"}},[_vm._v("mdi-information")]),_c('b',{staticClass:"grey--text",staticStyle:{"font-size":"14px"}},[_vm._v(_vm._s(_vm.$t('tools.project.log.dialog.download.noDataFound')))])],1)],1):_vm._e(),_c('v-spacer'),_c('TextButton',{staticClass:"ml-2",attrs:{"disabled":_vm.dateRange == null ||
          (_vm.exportFiles.protocol == false &&
            _vm.exportFiles.table == false &&
            _vm.exportFiles.json == false) ||
          _vm.noDataResp == true,"loading":_vm.loadingFiles,"text":_vm.$t('tools.project.log.dialog.download.button.export'),"buttonColor":"primary"},on:{"click":function($event){return _vm.exportProtocol()}}})],1)]},proxy:true}])})}
var staticRenderFns = []

export { render, staticRenderFns }