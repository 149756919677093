<template>
<div>
<TableListRowEditable
            :key="resetTable"
            :noKey="true"
            :noHover="true"
            :saveText="false"
            :width="dense ? 40 : null"
            v-on:save="$emit('update', true); dialogOpen = false"
            v-on:close="$emit('close'); dialogOpen = false"
            v-on:open="dialogOpen = true"
            :slotValue="true"
            descr=""
            controls
            :disabled="disabled"
            :loading="loading"
            :value="1"
            actions
            :listener="dialogOpen"
        >
        <template v-slot:value>
      

            <div >
                <IconButton v-if="!active"  icon="mdi-filter-variant"/>
                <IconButton class="grey lighten-2" style="border-radius: 50%;" v-else buttonColor="primary" icon="mdi-filter-variant-remove"/>
            </div>
        </template>
            <template v-slot:input>
                <div style="width: 400px">
                <slot name="input"/>
                </div>
            </template>

            <template v-slot:actions>
                <TextButton class="mr-2" buttonColor="#E5E5E5" v-show="filter" v-on:click="removeFilter()" :text="$t('helper.buttons.removeFilter')" dense icon="mdi-close"/>
            </template>
        </TableListRowEditable>
</div>
</template>


<script>

    import TextButton from '@components/button/TextButton.vue'
    import TableListRowEditable from '@components/table/TableListRowEditable.vue'
import IconButton from '../button/IconButton.vue'
export default {
    props: {
        active: {
            type: Boolean,
            default: false, 
            required: true
        },
        filter: {
            type: Boolean, 
            default: false
        },
        dense: {
            type: Boolean,
            default: false
        },
        disabled: {
            type: Boolean,
            default: false
        }, 
        loading: {
            type: Boolean, 
            default: false
        }, 


    },
    components: {
    TextButton,
    TableListRowEditable,
    IconButton
},
    data() {
        return {
            dialogOpen: false,
            resetTable: false,
        }   
    }, 


    methods: {
        removeFilter() {
            this.$emit('reset', null)
            this.resetTable = !this.resetTable
        },
        
    }

}
</script>