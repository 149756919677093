<template>
<div>
    
<TableListRowEditable
            :key="resetTable"
            :noKey="true"
            :noHover="true"
            controls
            :width="dense ? 40 : null"
            v-on:save="$emit('update',editDateRange)"
            v-on:open="editDateRange = dateRange"
            :slotValue="true"
            descr=""
            :disabled="disabled"
            :loading="loading"
            actions
            :value="1"
            listener
        >
    <template v-slot:value>
       <div v-if="!dense"  style="max-width: 100%; position: relative; display:flex; white-space:nowrap;">
             <!-- <v-row class="ma-0 font-weight-normal" align="center" style="width: 310px; font-weight: 300!important"> -->
            <TextInput  style="display: inline-block; min-width: 160px; font-weight: 300; pointer-events: none; user-select: none;" :value="dateRange != null ? $helper.formatDate(dateRange.start, false, false) : ''" :placeholder="$t('helper.units.date.startDate')"></TextInput> <div class="ml-2 mr-2 pt-3">-</div>
            <TextInput class="mr-5" style="display: inline-block; min-width: 160px; font-weight: 300;  pointer-events: none; user-select: none;" :value="dateRange != null ? $helper.formatDate(dateRange.end, false, false) : ''" :placeholder="$t('helper.units.date.endDate')"></TextInput>
        <!-- </v-row> -->
            </div>  

            <div v-else>
                           <IconButton  v-if="dateRange == null || dateRange.start == null" icon="mdi-calendar-range"/>

            <v-chip class="mr-2" v-else style="width: 255px!important; height: 36px; border-radius: 18px; cursor: pointer;">
                <v-row  style="height: 36px; width: 245px" class="ma-0" align="center" justify="center">
                    <v-icon class="mr-2" :size="20" style="margin-top: 1px;">mdi-calendar-range</v-icon>
                    <span class="mr-1">{{$helper.formatDate(dateRange.start, false, false)}}</span>
                    - 
                    <span class="ml-1">{{$helper.formatDate(dateRange.end, false, false)}}</span>
                </v-row>
            </v-chip>
            </div>
        </template>
            <template v-slot:input>
          <v-row class="ma-0" align="start">
              
      <vc-date-picker :locale="$route.params.lang" :min-date="min" :max-date="max"  id="date-picker" ref="calendar"  color="gray" title-position="left" width="400"  v-model="editDateRange" is-range />
         <v-divider class="mr-4 ml-5 mb-2 mt-2" vertical/>
          <div style="max-width: 200px" class="mt-3 pr-0">
                <TextButton class="mb-3" v-on:click="clickSelectButton(1)" block dense :text="$t('helper.text.last.sevendays')"/>
                <TextButton class="mb-3" v-on:click="clickSelectButton(2)" block dense :text="$t('helper.text.last.week')"/>
                <TextButton class="mb-3" v-on:click="clickSelectButton(3)" block dense :text="$t('helper.text.last.month')"/>
                <!-- <TextButton class="mb-3" v-on:click="selectLastYear()" block dense text="Letztes Jahr"/> -->




             </div>
         </v-row>
            </template>

            <template v-slot:actions>
                <TextButton buttonColor="#E5E5E5" v-on:click="removeFilter()" v-show="filter == true && dateRange != null" :text="$t('helper.buttons.removeFilter')" dense icon="mdi-close"/>
            </template>
        </TableListRowEditable>
</div>
</template>


<script>

    import TextButton from '@components/button/TextButton.vue'
    import TextInput from '@components/text/TextInput.vue'
    import TableListRowEditable from '@components/table/TableListRowEditable.vue'
import IconButton from '../button/IconButton.vue'
export default {
    props: {
        filter: {
            type: Boolean, 
            default: false
        },
        dense: {
            type: Boolean,
            default: false
        },
        dateRange: {
            required: true, 
            default: null
        }, 
        disabled: {
            type: Boolean,
            default: false
        }, 
        loading: {
            type: Boolean, 
            default: false
        }, 

        min: {
            default: null,
        }, 

        max: {
            default: Date.now()
        }

    },
    components: {
    TextButton,
    TableListRowEditable,
    TextInput,
    IconButton
},
    data() {
        return {
            resetTable: false,
            editDateRange: null,
            calendarDebouncer: false,
        }   
    }, 


    methods: {
        removeFilter() {

            this.$emit('update', null)
            this.resetTable = !this.resetTable
        },
        getParticularDayTimestamp(lastWeekDay) {
            var currentWeekMonday = new Date().getDate() - new Date().getDay() + 1;
            return new Date().setDate(currentWeekMonday - lastWeekDay);
        },

        clickSelectButton(type) {
            var end;
            switch(type) {
                case 1: end = this.selectLastSevenDays(); break;
                case 2: end = this.selectLastWeek(); break; 
                case 3: end = this.selectLastMonth(); break; 
                default: return;
            }

            if (end == null) return;
            // 
        },

        selectLastSevenDays() {
            if (this.calendarDebouncer == true) return;

            var dateHandle = {
                start: new Date(new Date().setDate(new Date().getDate() - 7)), 
                end: new Date()
            }

            if (this.min != null && dateHandle.start < this.min) dateHandle.start = this.min; 
            if (dateHandle.end > this.max) dateHandle.end = this.max; 
            
            this.editDateRange = dateHandle;

            // debouncer
            this.calendarDebouncer = true; 
            setTimeout(() => {this.calendarDebouncer = false}, 100)

            return dateHandle.end;

        },
        selectLastWeek() {
            if (this.calendarDebouncer == true) return;

            var dateHandle = {
                start: new Date(this.getParticularDayTimestamp(7)), 
                end: new Date(this.getParticularDayTimestamp(1))
            }

            if (this.min != null && dateHandle.start < this.min) dateHandle.start = this.min; 
            if (dateHandle.end > this.max) dateHandle.end = this.max; 
            
            this.editDateRange = dateHandle;

            // debouncer
            this.calendarDebouncer = true; 
            // this.$refs.calendar.move(dateHandle.end)
            setTimeout(() => {this.calendarDebouncer = false}, 100)

            return dateHandle.end;
            
            
        },
        selectLastMonth() {
            if (this.calendarDebouncer == true ) return;
            var date = new Date();
            var dateHandle = {
                start: new Date(date.getFullYear(), (date.getMonth() - 1), 1, 0, 0, 0, 0),
                end: new Date(date.getFullYear(), (date.getMonth()), 1, 23, 59, 59, 59)
            }

            dateHandle.end = new Date(dateHandle.end.setDate(dateHandle.end.getDate() - 1))

            
            if (this.min != null && dateHandle.start < this.min) dateHandle.start = this.min; 
            if (dateHandle.end > this.max) dateHandle.end = this.max; 
            
            this.editDateRange = dateHandle;

        //    // console.log(this.$refs.calendar)
            // debouncer
            this.calendarDebouncer = true; 
            // this.$refs.calendar.move(dateHandle.end)
            setTimeout(() => {this.calendarDebouncer = false}, 100)


            return dateHandle.end;


        },
    }

}
</script>