<template>

 <v-menu nudge-top="2" >
      <template v-slot:activator="{ on, attrs }">
      <div class="role-selector" v-bind="attrs"
          v-on="on">
        
        <v-hover v-slot="{ hover }">
          <v-row class="ma-0" style="padding-top: 10px" align="center">
            <span class="ml-5 font-weight-bold" style="font-size: 15px; user-select: none; color: rgba(0, 0, 0, .8)"  >
                <div v-if="noSelection == true">
                    <span v-if="selectedItem > 0">{{data[selectedItem - 1].text}}</span>
                    <span v-else class="grey--text" style="font-weight: 500; font-size: 16px">{{noSelectionText}}</span>
                </div>
                <div v-else>
                    <span>{{data[selectedItem].text}}</span>
                </div>
            </span> 
            <v-spacer/>
            <v-icon size="20" class="mr-3"  :class="hover ? 'primary--text' : ''">mdi-chevron-down</v-icon>
          </v-row>
          </v-hover>
          
      </div>
      </template>
      <v-list nav width="100%">

          
        <v-list-item-group v-model="selectedItem">
        <v-list-item v-if="noSelection == true"  :style="selectedItem == 0 ? 'cursor: default!important; pointer-events: none!important' : '' " dense :ripple="false" >
          <v-list-item-title class="ml-1 grey--text" style="font-size: 15px">Keine Auswahl</v-list-item-title>  
        </v-list-item>


        <v-list-item :style="selectedItem == (noSelection == true ? index + 1 : index) ? 'cursor: default!important; pointer-events: none!important' : ''"  dense :ripple="false" v-for="(type, index) in data" :key="index">
          <v-list-item-title class="ml-1" :class="selectedItem == (noSelection == true ? index + 1 : index)  ? 'primary--text font-weight-bold' : ''" style="font-size: 15px">{{type.text}}</v-list-item-title> 
        </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-menu>

</template>


<script>

export default {
    props: {
        value: {
          default: null
        },
        data: {
            required: true,
        }, 
        noSelection: {
            type: Boolean, 
            default: true
        }, 
        noSelectionText: {
            type: String,
        }
    },

    watch: {
      value: {
        handler() {
          
          if (this.value != null) {
            var index = this.data.findIndex(datum => datum.id == this.value)
            if (index == null || index == -1) return; 

            if (!this.noSelection) return (this.selectedItem = index)
            else return (this.selectedItem = index + 1)
          }
        }, immediate: true, deep: true
      },
      selectedItem() {
        if (this.selectedItem == null || (!this.noSelection && (this.data[this.selectedItem] == null || this.data[this.selectedItem].id == null)) || (this.noSelection == true && (this.data[this.selectedItem - 1] == null || this.data[this.selectedItem - 1].id == null))) return this.$emit('update')
        if (!this.noSelection) return this.$emit('update', this.data[this.selectedItem].id || null)
        else return this.$emit('update', this.data[this.selectedItem - 1].id || null)
      }
    },
    data() {
        return {
            selectedItem: 0,
        
        }
    }

}

</script>


<style>
.role-selector {
  height: 40px; 
  width: 100%; 
  background-color: #F0F0F0;
  border-radius: 20px; 
  overflow: hidden;
  cursor: pointer;
  transition: background-color 200ms linear;
}
.role-selector:hover {
  background-color: #E0E0E0; 
  transition: background-color 200ms linear;
}
</style>