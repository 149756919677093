<template>
  <div style="width: 100%" class="mb-2 mt-2">
    <div>
      <b style="font-size: 14px"
        >{{ $t('tools.project.log.filter.sortBy') }}:</b
      >
      <v-row class="ma-0 mb-3 mt-1" align="center">
        <div style="width: 70%">
          <SelectorInput
            :value="filterHandle.sortBy"
            v-on:update="filterHandle.sortBy = $event"
            :noSelection="false"
            :data="$t('tools.project.log.filter.sortByFilter')"
          />
        </div>
        <v-spacer />

        <IconButton
          class="mr-2"
          :class="filterHandle.sortDescending ? 'descending-button' : ''"
          :color="filterHandle.sortDescending ? 'primary' : ''"
          v-on:click="
            filterHandle.sortDescending = !filterHandle.sortDescending
          "
          icon="mdi-sort-alphabetical-descending"
        />
        <IconButton
          :class="!filterHandle.sortDescending ? 'descending-button' : ''"
          :color="!filterHandle.sortDescending ? 'primary' : ''"
          v-on:click="
            filterHandle.sortDescending = !filterHandle.sortDescending
          "
          icon="mdi-sort-alphabetical-ascending"
        />
      </v-row>
    </div>
    <!-- <div v-show="!violations" class="mt-3">
<b style="font-size: 14px">{{$t('tools.project.log.filter.minDuration')}}:</b>
           <v-row class="ma-0 mb-3 mt-1" align="center">
                    
                    <TextInput :readonly="filterHandle.violations == true" :disabled="filterHandle.violations == true" :key="updateData" placeholder="" :numberBounds="{min: 0, max: 10000000}" style="max-width: 50%" :suffix="$t('helper.units.minutes.long')" v-on:change="filterHandle.minDuration = $event" number :value="filterHandle.minDuration"/>

                    <div v-if="selectedWhitelist != null && selectedWhitelist.allowedTime != null" class="ml-5" style="padding-bottom: 2px;">
                      <Checkbox :key="filterHandle.violations" :src="filterHandle.violations" v-on:update="filterHandle.violations = $event" :text="$t('tools.project.log.filter.checkboxViolations')"/>
                    
                    </div>
                  </v-row>
</div> -->

    <div class="mt-3" :class="violations == true ? 'pb-3' : ''">
      <div class="mb-1">
        <b style="font-size: 14px"
          >{{ $t('tools.project.log.filter.vehicleGroup') }}:</b
        >
      </div>
      <SelectorInput
        :value="filterHandle.whitelistId"
        :key="updateData"
        :disabled="whitelistData == null"
        v-on:update="filterHandle.whitelistId = $event"
        :noSelection="true"
        :noSelectionText="
          $t('tools.project.log.filter.vehicleGroupNoSelection')
        "
        :data="
          whitelistData != null
            ? whitelistData.map((whitelist) => {
                return {id: whitelist.id, text: whitelist.name};
              })
            : null
        "
      />
    </div>

    <div v-show="!violations" class="mt-3 pb-3">
      <div class="mb-1">
        <b style="font-size: 14px"
          >{{ $t('tools.project.log.filter.gate') }}:</b
        >
      </div>
      <SelectorInput
        :value="filterHandle.gate"
        :key="updateData"
        v-on:update="filterHandle.gate = $event"
        :noSelection="true"
        :noSelectionText="$t('tools.project.log.filter.gateNoSelection')"
        :data="[
          {id: 'ENTER', text: 'Einfahrt'},
          {id: 'EXIT', text: 'Ausfahrt'},
        ]"
      />
    </div>
  </div>
</template>

<script>
import SelectorInput from '@components/text/SelectorInput.vue';
// import TextInput from '@components/text/TextInput.vue';
// import Checkbox from '@components/text/Checkbox.vue';
import IconButton from '@components/button/IconButton.vue';
export default {
  name: 'FilterSelection',
  components: {
    SelectorInput,
    // TextInput,
    // Checkbox,
    IconButton,
  },

  props: {
    filter: {
      default: null,
    },
    whitelistData: {
      default: null,
      required: true,
    },
    violations: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      updateData: false,
      filterHandle: {
        sortDescending: false,
        search: '',
        sortBy: 'TIMESTAMP',
        minDuration: 0,
        whitelistId: null,
        dateRange: null,
        violations: false,
        gate: null,
      },
    };
  },

  created() {
    if (this.filter == null) return;

    this.filterHandle = {
      sortDescending: this.filter.sortDescending,
      search: this.filter.search,
      sortBy: this.filter.sortBy,
      minDuration: this.filter.minDuration,
      whitelistId: this.filter.whitelistId,
      dateRange: this.filter.dateRange,
      violations: this.filter.violations,
      gate: this.filter.gate,
    };
    this.updateData = !this.updateData;
  },

  watch: {
    // filter: {
    //     handler() {
    //         this.filterHandle = JSON.parse(JSON.stringify(this.filter))
    //     }, immediate: true, deep: true
    // },
    //         'filterHandle.whitelistId': {
    //   handler() {
    //     this.filterHandle.minDuration = 0
    //     this.minDurationDebouncer = 0;
    //     this.updateData = !this.updateData
    //   }, immediate: true, deep: true
    // },

    'filterHandle.violations': {
      handler(newVal, oldVal) {
        if (oldVal != newVal && newVal == false) {
          this.filterHandle.minDuration = 0;
          this.updateData = !this.updateData;
        }
      },
      immediate: true,
      deep: true,
    },

    filterHandle: {
      handler() {
        if (
          this.filterHandle.violations == true &&
          this.selectedWhitelist != null &&
          this.selectedWhitelist.allowedTime != null
        ) {
          this.filterHandle.minDuration = this.selectedWhitelist.allowedTime;
        }

        if (
          this.selectedWhitelist != null &&
          this.selectedWhitelist.allowedTime != null &&
          this.filterHandle.minDuration == this.selectedWhitelist.allowedTime
        ) {
          this.filterHandle.minDuration = this.selectedWhitelist.allowedTime;
        }

        if (
          this.selectedWhitelist != null &&
          this.selectedWhitelist.allowedTime == null
        ) {
          this.filterHandle.violations = false;
          this.filterHandle.minDuration = 0;
        }

        return this.$emit('update', this.filterHandle);
      },
      deep: true,
    },
  },

  computed: {
    selectedWhitelist() {
      if (this.whitelistData == null || this.filterHandle.whitelistId == null)
        return;

      var whitelist = this.whitelistData.reduce((a, b) =>
        a.id == this.filterHandle.whitelistId ? a : b,
      );
      if (whitelist == null || whitelist.id == null) return;
      else return whitelist;
    },
  },
};
</script>
