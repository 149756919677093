<template>
  <div
    :style="deleteElement.status == true ? 'user-select: none' : ''"
    style="position: relative; border-radius: 10px; overflow: hidden"
    class="ma-0 elevation-0 pt-1 grey lighten-5"
  >
    <div class="grey lighten-5">
      <div style="height: 55px">
        <v-row class="ma-0 pt-1 mr-4 mt-1 pb-1" align="center">
          <TextInput
            v-show="searchable"
            v-on:change="updatedSearchValueEvent"
            :value="searchValue"
            :placeholder="searchText"
            class="ml-3 mr-3"
            :disabled="loading"
            icon="mdi-magnify"
            style="max-width: 300px"
            dense
            v-on:keydown="searchKeyboardEvent"
          >
            <template v-slot:append>
              <div
                v-show="serverSearch && searchValueHolder.length > 0"
                style="width: 25px"
              >
                <IconButton
                  :disabled="searchValueHolder == searchValue"
                  v-on:click="enterServerSearchEvent()"
                  buttonColor="primary"
                  style="position: absolute; top: 2px; right: 10px"
                  color="primary"
                  icon="mdi-chevron-right"
                />
              </div>
            </template>
          </TextInput>

          <div v-show="group == true" style="position: relative; height: 36px">
            <!-- <v-img :style="((groupByValue != null && loadGroupByClose == true) || (groupByValue == null && loadGroupBy == true)) ? '' : 'opacity: 0;'" style="max-width: 20px; padding: 7px; top: 7px; position: absolute; " preload class="mr-2 ml-2" src="./loading.gif"/> -->
            <v-chip
              class="pr-4 pl-4"
              style="height: 36px; border-radius: 18px !important"
              v-if="groupByValue != null && loadGroupByClose != true"
              color="rgba(240, 240, 240, .8)"
              close
              @click:close="removeGroupBy()"
            >
              <span style="font-size: 14px; padding-top: 2px">
                <v-icon size="22" class="mr-2" style="margin-bottom: 3px"
                  >mdi-filter-variant</v-icon
                >
                <b class="mr-1">{{
                  formattedHeader.reduce((a, b) =>
                    a.value == groupByValue ? a : b,
                  ).text
                }}</b>
              </span>
            </v-chip>
            <div v-else-if="groupByValue == null && loadGroupBy != true">
              <v-menu nudge-bottom="3" offset-y v-model="groupByMenu">
                <template v-slot:activator="{on, attrs}">
                  <v-btn
                    class="ml-2"
                    icon
                    :ripple="false"
                    v-bind="attrs"
                    v-on="on"
                  >
                    <v-icon size="22">mdi-filter-variant</v-icon>
                  </v-btn>
                </template>

                <v-list width="200">
                  <div
                    style="font-size: 14px"
                    class="font-weight-bold ml-3 mb-2 mt-1"
                  >
                    Gruppieren nach:
                  </div>
                  <v-list-item
                    v-for="(item, index) in formattedHeader.filter(
                      (header) =>
                        header.value != 'actions' && header.value != 'status',
                    )"
                    :key="index"
                    dense
                    :ripple="false"
                    v-on:click="selectGroupBy(item.value)"
                  >
                    <v-list-item-title>{{ item.text }}</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </div>
          </div>

          <!-- <TextButton
            textColor="white"
            buttonColor="primary"
            v-show="groupByValue != null || (searchValue != null && searchValue.length > 0)"
            v-on:click="clearSearch"
            class="mr-2"
            dense
            icon="mdi-close"
            text="Suche löschen"
          /> -->
          <slot name="actions" />
          <!-- <IconButton
            :disabled="true"
            
            class="mr-2 ml-3"
            icon="mdi-download"
          /> -->
          <IconButton
            :loading="loading"
            buttonColor="primary"
            v-on:click="$emit('update', true)"
            icon="mdi-refresh"
          />
        </v-row>
      </div>
      <!-- <div style="position: absolute; top: 103px; z-index: 5; width: 100%; height: 1px; background-color: rgba(220, 220, 220, 1)"></div> -->
      <div
        v-if="
          (groupByValue != null && loadGroupByClose == true) ||
          (groupByValue == null && loadGroupBy == true)
        "
        style="
          position: absolute;
          top: 104px;
          z-index: 5;
          width: 100%;
          height: calc(100% - 92px);
          opacity: 0.6;
          background-color: white;
        "
      >
        <LoadingProjectData
          text
          style="
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
          "
        />
      </div>

      <v-divider style="opacity: 0.3" />
      <div style="position: relative">
        <v-overlay
          absolute
          color="grey lighten-5"
          :opacity="0.8"
          :value="loading"
        >
          <v-progress-circular
            indeterminate
            width="3"
            size="40"
            color="primary"
            class="center"
          ></v-progress-circular>
        </v-overlay>
        <v-data-table
          :headers="formattedHeader"
          :items="data"
          :key="resetPage"
          :items-per-page="20"
          :height="hideHeader ? 'calc(100vh - 104px)' : 'calc(100vh - 159px)'"
          fixed-header
          :no-data-text="
            loading
              ? ''
              : searchValue.length > 0
              ? $t('helper.text.noSearchResult')
              : $t('helper.text.noData')
          "
          calculate-widths
          hide-default-footer
          :server-items-length="dataTotal"
          :group-by="groupByValue"
          :class="tableImage == true ? 'row-height-50' : ''"
          class="elevation-0 table"
          :search="serverSearch ? null : searchValue"
          v-on:pagination="paginationUpdateEvent"
          @click:row="$emit('click', $event)"
        >
          <template v-slot:no-data>
            <div v-if="!loading" style="font-size: 14px" class="grey--text">
              <div v-if="error == true">
                <v-row
                  class="ma-0 mt-4 error--text"
                  align="center"
                  justify="center"
                >
                  <v-icon class="mr-1" size="17" color="error"
                    >mdi-alert-circle</v-icon
                  >

                  <b style="font-size: 14px">{{
                    $t('helper.text.searchTableError')
                  }}</b>
                </v-row>

                <TextButton
                  v-on:click="$emit('update')"
                  class="mt-3"
                  dense
                  buttonColor="primary"
                  icon="mdi-reload"
                  :text="$t('helper.buttons.reload')"
                />
              </div>
              <div v-else-if="searchValue != null && searchValue.length > 0">
                {{ $t('helper.text.noSearchResult') }}
              </div>
              <div v-else>
                {{ $t('helper.text.noData') }}
              </div>
            </div>
          </template>
          <template v-slot:footer="{props}">
            <div
              style="border-top: 1px solid #c5c5c5 !important; font-size: 13px"
              class="grey lighten-5"
            >
              <v-row
                style="padding-top: 1px"
                class="ma-0 mr-2 pb-2"
                align="center"
              >
                <!-- <v-icon size="17">mdi-alert-circle</v-icon> -->
                <!-- <v-icon v-show="refreshTime != null" class="ml-2" size="16">mdi-clock-outline</v-icon>
              <span v-show="refreshTime != null" class="pl-1">{{$helper.formatDate(refreshTime, false)}}</span>-->
                <v-spacer />

                <span v-if="props.pagination.itemsLength > 0" class="mr-5">
                  <b>
                    {{ props.pagination.pageStart + 1 }}
                    <span class="ml-1 mr-1">-</span>
                    {{ props.pagination.pageStop }}
                  </b>
                  <span class="ml-1 mr-1">{{
                    $t('helper.table.totalOf')
                  }}</span>
                  {{
                    dataTotal == null ? props.pagination.itemsLength : dataTotal
                  }}
                </span>
                <IconButton
                  class="mr-1"
                  :disabled="props.pagination.pageStart == 0"
                  v-on:click="props.options.page -= 1"
                  icon="mdi-chevron-left"
                />
                <IconButton
                  :disabled="
                    props.pagination.pageStop == props.pagination.itemsLength
                  "
                  v-on:click="props.options.page += 1"
                  icon="mdi-chevron-right"
                />
              </v-row>
            </div>
          </template>
          <template v-slot:[`group.header`]="{group, groupBy, isOpen, items}">
            <th
              :colspan="formattedHeader.length"
              class="group-header"
              :style="
                isOpen ? 'border-bottom: 1px solid transparent!important;' : ''
              "
            >
              <!-- <div style="position: relative; background-color: red;"> -->
              <v-row class="ma-0" align="center">
                <!-- <div>m {{isOpen}}</div> -->

                <span
                  class="font-weight-light ml-1"
                  style="padding-bottom: 1px; font-size: 15px"
                >
                  {{
                    formattedHeader.reduce((a, b) =>
                      a.value == groupBy ? a : b,
                    ).text
                  }}:
                  <span v-if="groupBy != 'status'">
                    <i v-if="items[0][groupBy] == null">keine Daten</i>
                    <b v-else class="font-weight-bold ml-1">{{
                      items[0][groupBy]
                    }}</b>
                  </span>
                  <span class="ml-1" v-else>
                    <v-icon
                      size="20"
                      class="mr-1"
                      :color="
                        formattedHeader.reduce((a, b) =>
                          a.value == 'status' ? a : b,
                        ).statusData[group].color
                      "
                      style="margin-bottom: 2px"
                      >{{
                        formattedHeader.reduce((a, b) =>
                          a.value == 'status' ? a : b,
                        ).statusData[group].icon
                      }}</v-icon
                    >
                    <b class="font-weight-bold">{{
                      formattedHeader.reduce((a, b) =>
                        a.value == 'status' ? a : b,
                      ).statusData[group].descr
                    }}</b>
                  </span>
                </span>

                <v-spacer />
                <span class="font-weight-light mr-2" style="padding-bottom: 1px"
                  >{{ items.length }} Element(e)</span
                >
                <!-- <IconButton class="mr-2" dense v-on:click="toggle()" :icon="!isOpen ? 'mdi-chevron-down' : 'mdi-chevron-up'"/> -->
              </v-row>
            </th>
          </template>

          <template v-slot:progress>
            <div style="position: absolute" />
          </template>

          <template
            v-for="(header, index) in formattedHeader.map(({value}) => value)"
            v-slot:[`item.${header}`]="{value, item}"
          >
            <div
              style="user-select: none"
              :key="index"
              :class="formattedHeader[index].bold ? 'font-weight-bold' : ''"
            >
              <div
                v-if="formattedHeader[index].status != null"
                class="mr-4"
                :style="item.archived != true ? 'cursor: help;' : ''"
              >
                <v-tooltip
                  :disabled="item.archived == true"
                  right
                  open-delay="500"
                  style="opacity: 1 !important"
                >
                  <template v-slot:activator="{on, attrs}">
                    <div v-bind="attrs" v-on="on">
                      <v-icon size="20" v-if="item.archived == true">
                        mdi-archive
                      </v-icon>
                      <v-icon
                        size="20"
                        v-else-if="
                          value == null ||
                          !Object.keys(
                            formattedHeader[index].statusData ||
                              formattedHeader[index].statusData[value] == null,
                          ).includes(String(value))
                        "
                        class="ml-0"
                        >mdi-checkbox-blank-circle</v-icon
                      >
                      <v-icon
                        size="20"
                        v-else
                        :color="formattedHeader[index].statusData[value].color"
                        class="ml-0"
                        >{{
                          formattedHeader[index].statusData[value].icon
                        }}</v-icon
                      >
                    </div>
                  </template>
                  <div>
                    <b
                      v-if="formattedHeader[index].statusData[value] != null"
                      >{{ formattedHeader[index].statusData[value].descr }}</b
                    >
                    <span v-if="item.lastStatus != null"
                      >({{ $helper.formatLastHours(item.lastStatus) }})</span
                    >
                  </div>
                </v-tooltip>
              </div>

              <div v-else>
                <v-hover
                  :disabled="value == null || value.length == 0"
                  v-slot="{hover}"
                >
                  <span
                    :style="
                      (value == null || value.length == 0) &&
                      formattedHeader[index].slot == null
                        ? 'pointer-events: none!important'
                        : ''
                    "
                    :class="
                      formattedHeader[index].link
                        ? 'link'
                        : formattedHeader[index].copyable ||
                          formattedHeader[index].filterIcon
                        ? 'copy'
                        : ''
                    "
                    v-on:click="
                      $emit(
                        formattedHeader[index].link
                          ? 'link'
                          : formattedHeader[index].copyable
                          ? 'copy'
                          : '',
                        {data: item, col: header},
                      )
                    "
                  >
                    <span
                      :class="
                        formattedHeader[index].bold ? 'font-weight-bold' : ''
                      "
                    >
                      <!-- <v-row class="ma-0" align="center"> -->
                      <slot
                        v-if="formattedHeader[index].slot != null"
                        :name="formattedHeader[index].slot"
                        :data="item"
                      />
                      <span v-else-if="value == null || value.length == 0"
                        >-</span
                      >
                      <div
                        v-else-if="formattedHeader[index].date == true"
                        style="display: inline-block"
                      >
                        {{
                          $helper.formatDate(
                            value,
                            false,
                            formattedHeader[index].datetime || false,
                          )
                        }}
                      </div>

                      <div v-else style="display: inline-block">
                        <div
                          v-if="
                            formattedHeader[index].shorten != null &&
                            formattedHeader[index].shorten > 0
                          "
                        >
                          {{
                            $helper.shortenText(
                              value,
                              formattedHeader[index].shorten,
                            )
                          }}
                        </div>
                        <div v-else>{{ value }}</div>
                      </div>
                      <v-icon
                        color="primary"
                        v-if="formattedHeader[index].openTab == true"
                        :style="hover ? '' : 'opacity: 0; pointer-events: none'"
                        style="margin-bottom: 2px"
                        size="16"
                        class="ml-2"
                        >mdi-open-in-new</v-icon
                      >
                      <v-icon
                        color="primary"
                        v-else-if="formattedHeader[index].copyable == true"
                        :style="hover ? '' : 'opacity: 0; pointer-events: none'"
                        style="margin-bottom: 2px"
                        size="16"
                        class="ml-2"
                        >mdi-content-copy</v-icon
                      >
                      <v-icon
                        color="primary"
                        v-else-if="formattedHeader[index].filterIcon == true"
                        :style="hover ? '' : 'opacity: 0; pointer-events: none'"
                        style="margin-bottom: 2px"
                        size="18"
                        class="ml-1"
                        >mdi-filter-variant</v-icon
                      >
                      <!-- </v-row> -->
                    </span>
                  </span>
                </v-hover>
              </div>
            </div>
          </template>
          <template v-slot:[`item.actions`]="{item}">
            <v-row class="ma-0" align="center" justify="end">
              <!-- <IconButton class="mr-2" dense icon="mdi-plus"/> -->
              <IconButton
                v-if="showInfoButton"
                v-on:click="$emit('infoDialog', item.id)"
                class="mr-2"
                dense
                icon="mdi-information"
              />
              <slot v-else name="table-action" :data="item" />
              <IconButton
                v-if="showInfoButton"
                v-on:click="$emit('configDialog', item.id)"
                class="mr-2"
                dense
                :icon="settingsIcon"
              />
              <IconButton
                :disabled="
                  (disableDelete != null && item[disableDelete] != null) ||
                  item.archived == true
                "
                class="mr-2"
                v-on:click="deleteElementEvent(item)"
                dense
                :icon="archive ? 'mdi-archive' : 'mdi-delete'"
              />

              <!-- <v-menu disabled :value="moreMenuOpen == item.id && resetMenu == true ? false : null" v-on:input="inputEvent($event, item.id)" offset-x nudge-left="235">
      <template v-slot:activator="{ on, attrs, value }">
      <span class="mr-2" v-bind="attrs"
          v-on="on"  :style="value ? 'background-color: rgba(220, 220, 220, 1); border-radius: 20px;' : 'pointer-events: none'">
      <IconButton :disabled="true"  dense :ripple="false" :color="value ? 'black' : ''" icon="mdi-dots-vertical"/>
      </span>
      
        
      </template>
      
      <v-list width="200">
        <v-list-item
          v-for="(item, index) in formattedHeader.filter(header => header.value != 'actions' && header.value != 'status')"
          :key="index"
          dense
          :ripple="false"
          v-on:click="selectGroupBy(item.value)"
        >
          <v-list-item-title>{{ item.text }}</v-list-item-title>
        </v-list-item>
      </v-list>
            </v-menu>-->
            </v-row>
          </template>
        </v-data-table>
      </div>

      <v-dialog
        @click:outside="closeDeleteDialog"
        v-model="deleteElement.status"
        width="450"
        :persistent="deleteElement.loading"
      >
        <DialogTemplate
          :closeable="!deleteElement.loading"
          v-on:close="closeDeleteDialog"
          :title="`${elementType != null ? elementType : ''} löschen`"
        >
          <template v-slot:content>
            <div class="pb-1">
              <span style="user-select: none; font-size: 15px">
                Durch das Löschen des
                <span v-if="elementType != null">{{ elementType }}</span>
                <span v-else>Element</span>s
                <span
                  v-if="
                    deleteElement.data != null &&
                    deleteElement.data.name != null
                  "
                  class="primary--text"
                  >{{ deleteElement.data.name }}</span
                >
                werden auch alle mit ihm verbundenen Daten vollständig gelöscht.
                <br />
                <b>Dieser Vorgang kann nicht widerrufen werden.</b>
                <br />
              </span>
              <slot name="delete-dialog" :data="deleteElement.data" />
            </div>
          </template>

          <template v-slot:actions>
            <v-row class="ma-0 pt-3" align="center" justify="end">
              <TextButton
                :disabled="deleteElement.loading"
                v-on:click="closeDeleteDialog"
                text="Abbrechen"
                class="mr-2"
              />
              <TextButton
                :loading="deleteElement.loading"
                v-on:click="consetDeletionEvent"
                buttonColor="primary"
                text="Löschen"
              />
            </v-row>
          </template>
        </DialogTemplate>
      </v-dialog>
    </div>
  </div>
</template>

<script>
import IconButton from '@components/button/IconButton.vue';
import TextButton from '@components/button/TextButton.vue';

import TextInput from '@components/text/TextInput.vue';
import LoadingProjectData from '@components/loading/LoadingProjectData.vue';
import DialogTemplate from '@components/dialog/DialogTemplate.vue';
// import XLSX from 'xlsx';

// import IconButton from "@components/button/IconButton.vue"

export default {
  name: 'TableTemplate',
  components: {
    IconButton,
    TextButton,
    TextInput,
    LoadingProjectData,
    DialogTemplate,
  },
  props: {
    error: {
      type: Boolean,
      default: false,
    },
    tableImage: {
      default: false,
    },
    dataTotal: {
      dataTotal: {
        type: Number,
        default: null,
      },
    },
    clickable: {
      type: Boolean,
      default: false,
    },
    serverSearch: {
      type: Boolean,
      default: false,
    },
    archive: {
      type: Boolean,
      default: false,
    },
    showInfoButton: {
      type: Boolean,
      default: true,
    },
    disableDelete: {
      default: null,
    },
    settingsIcon: {
      type: String,
      default: 'mdi-cog',
    },
    deleteEvent: {
      type: Boolean,
    },
    elementType: {
      type: String,
    },
    refreshTime: {
      type: Number,
    },
    loading: {
      type: Boolean,
      required: true,
    },
    title: {
      type: String,
      default: '',
    },
    headers: {
      type: Array,
      required: true,
    },
    src: {
      required: true,
    },
    search: {
      type: String,
    },
    groupBy: {
      type: String,
    },
    searchText: {
      default: 'Durchsuchen...',
    },
    actions: {
      type: Boolean,
      default: true,
    },
    group: {
      type: Boolean,
      default: true,
    },

    searchable: {
      type: Boolean,
      default: true,
    },
    hideHeader: {
      type: Boolean,
      default: false,
    },
    resetPage: {
      type: Boolean,
    },
  },
  data() {
    return {
      searchValueHolder: '',
      loadGroupByClose: false,
      loadGroupBy: false,
      groupByValue: null,
      groupByMenu: false,
      showSelector: false,
      searchValue: '',
      resetMenu: false,
      moreMenuOpen: null,
      deleteElement: {
        status: false,
        data: null,
        loading: false,
        timeout: null,
      },
      updateTable: false,
    };
  },

  created() {
    if (
      this.$route.query != null &&
      this.$route.query.groupBy != null &&
      this.$route.query.groupBy.length > 0
    ) {
      this.selectGroupBy(this.$route.query.groupBy);
    }

    if (
      this.$route.query != null &&
      this.$route.query.search != null &&
      this.$route.query.search.length > 0
    ) {
      this.searchValue = this.$route.query.search;
    }

    document.addEventListener('wheel', this.onScroll);
  },

  watch: {
    groupBy: {
      handler() {
        if (this.groupBy != null && this.groupBy.length > 0)
          this.selectGroupBy(this.groupBy);
      },
      immediate: true,
    },
    search: {
      handler() {
        if (this.search != null && this.search.length > 0)
          this.searchValue = this.search;
      },
      immediate: true,
    },
    deleteEvent() {
      if (this.deleteElement.timeout != null)
        clearTimeout(this.deleteElement.timeout);
      if (this.deleteEvent == true) this.closeDeleteDialog();
      else if (this.deleteEvent == false) this.deleteElement.loading = false;
    },
  },

  computed: {
    data() {
      if (this.src == null) return [];
      else return this.src;
    },
    formattedHeader() {
      var headerArr = [...this.headers.filter((header) => header.hide != true)];
      headerArr = headerArr.map((object) => {
        return {...object, class: 'test'};
      });
      if (
        this.actions == true &&
        headerArr.findIndex((headerItem) => headerItem.value == 'actions') != 1
      )
        headerArr.push({
          value: 'actions',
          align: 'end',
          width: 175,
          sortable: false,
        });
      return headerArr.map((header) => {
        return {...header, class: 'grey lighten-5'};
      });
    },
  },

  methods: {
    paginationUpdateEvent(eventHandle) {
      if (this.serverSearch == true) return this.$emit('page', eventHandle);
    },
    clearSearch() {
      this.removeGroupBy();
      this.searchValue = '';
    },
    consetDeletionEvent() {
      this.deleteElement.loading = true;
      this.$emit('delete-element', this.deleteElement.data);
      this.deleteElement.timeout = setTimeout(() => {
        if (this.deleteElement.loading == true) {
          this.$toast.error('Fehler wegen einer Zeitüberschreitung');
          this.deleteElement.loading = false;
        }
      }, 20000);
    },

    closeDeleteDialog() {
      this.deleteElement.status = false;
      this.deleteElement.loading = false;
      this.$emit('delete-close');
      setTimeout(() => {
        this.deleteElement.data = null;
      }, 150);
    },
    deleteElementEvent(item) {
      this.deleteElement.data = item;
      this.deleteElement.status = true;
    },
    inputEvent(eventHandle, id) {
      if (eventHandle == true) this.moreMenuOpen = id;
      else {
        this.moreMenuOpen = null;
        this.resetMenu = false;
      }
    },
    onScroll() {
      if (this.moreMenuOpen != null) {
        this.resetMenu = true;
        setTimeout(() => {
          this.moreMenuOpen = null;
          this.resetMenu = false;
        }, 1);
      }
    },

    searchKeyboardEvent(eventHandle) {
      if (eventHandle.keyCode == 13 && this.searchValueHolder.length > 0)
        this.enterServerSearchEvent();
    },
    updatedSearchValueEvent(searchEvent) {
      this.searchValueHolder = searchEvent;

      if (searchEvent.length == 0) {
        this.searchValueHolder = '';
        this.enterServerSearchEvent();
      }

      if (!this.serverSearch) this.searchValue = searchEvent;
    },

    enterServerSearchEvent() {
      this.searchValue = this.searchValueHolder;
      this.$emit('search', this.searchValue);
    },

    removeGroupBy() {
      this.loadGroupBy = false;
      this.groupByMenu = false;

      this.loadGroupByClose = true;

      this.groupByValue = null;
    },
    selectGroupBy(itemValue) {
      this.loadGroupByClose = false;

      this.groupByMenu = false;
      this.loadGroupBy = true;
      this.groupByValue = itemValue;
    },
    // downloadData() {
    //   const groupBy = function(xs, key) {
    //     return xs.reduce(function(rv, x) {
    //       (rv[x[key]] = rv[x[key]] || []).push(x);
    //       return rv;
    //     }, {});
    //   };

    //   try {
    //     var wb = XLSX.utils.book_new();

    //     // define workbook metadata (props)
    //     wb.Props = {Title: this.title, Author: "alinotc GmbH & Co. KG", CreatedDate: new Date()};

    //     if (this.groupByValue == null || this.groupByValue.length == 0) {
    //       // create new sheet
    //       wb.SheetNames.push(this.title);

    //       // filter data by selected header values
    //       var tableDataHandle = [(this.headers.map(({text}) => text))];

    //       this.data.forEach(item => {
    //         var itemDataHandle = [];
    //         this.headers.forEach(header => {item[header.value] != null ? itemDataHandle.push(item[header.value]) : itemDataHandle.push('-')});
    //         tableDataHandle.push(itemDataHandle)
    //       })

    //       // throw error in case no data was found; download button is generally disabled if no data
    //       if (tableDataHandle == null || tableDataHandle.length == 0) throw new Error();

    //       // add data to worksheet
    //       wb.Sheets[this.title] = XLSX.utils.aoa_to_sheet(tableDataHandle);
    //     } else {
    //       var groupedData = groupBy(this.data, this.groupByValue)

    //       Object.keys(groupedData).forEach(group => {
    //           wb.SheetNames.push((group == undefined ? 'Sonstiges' : group));
    //           var tableSheetDataHandle = [(this.headers.map(({text}) => text))];
    //           groupedData[group].forEach(item => {
    //             var itemDataHandle = [];
    //             this.headers.forEach(header => {item[header.value] != null ? itemDataHandle.push(item[header.value]) : itemDataHandle.push('-')});
    //             tableSheetDataHandle.push(itemDataHandle)
    //           })

    //           if (tableSheetDataHandle == null || tableSheetDataHandle.length == 0) throw new Error();
    //           wb.Sheets[(group == undefined ? 'Sonstiges' : group)] = XLSX.utils.aoa_to_sheet(tableSheetDataHandle);

    //       })

    //     }

    //     // download file
    //     XLSX.writeFile((wb), `${this.title}_${(+ new Date())}.xlsx`);

    //   } catch (err){
    //     // console.log(err)
    //     this.$toast.error('Fehler beim Herunterladen der Daten')
    //   }

    // },
  },
};
</script>

<style>
.v-data-table.row-height-50 td {
  height: 90px !important;
}
</style>
<style scoped>
.group-header {
  height: 35px !important;
  padding-left: 10px !important;
  background-color: rgba(220, 220, 220, 1);
  cursor: default;
}

.link {
  cursor: pointer;
}
.link:hover {
  color: var(--v-primary-base);
}

.copy {
  cursor: pointer;
}
.copy:hover {
  color: var(--v-primary-base);
}

.table {
  overflow: -moz-scrollbars-none;
  -ms-overflow-style: none;
}
.table ::-webkit-scrollbar {
  width: 0 !important;
}

.table ::-webkit-scrollbar {
  display: none;
}
</style>
