var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticStyle:{"position":"relative","user-select":"none !important","overflow":"hidden !important"}},[_c('DialogTemplate',{attrs:{"oncontextmenu":"return false;","title":((_vm.$t('tools.project.violations.dialog.vehiclelog.header', {
      id:
        _vm.data != null && _vm.data.id != null
          ? ("Nr. " + (_vm.$helper.formatID(_vm.data.id)))
          : '',
    })) + " (" + (_vm.data.enterGateLog.licensePlate) + ")")},on:{"close":function($event){return _vm.$emit('close')}},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('div',{staticClass:"pb-2"},[_c('v-row',{staticClass:"ma-0 pr-1",attrs:{"align":"center","justify":"center"}},[_c('div',{staticClass:"image mb-3"},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
    var hover = ref.hover;
return [_c('div',{staticStyle:{"border-radius":"10px","height":"100%","width":"100%","position":"relative"}},[_c('inner-image-zoom',{key:_vm.data.id,attrs:{"hideHint":"","src":_vm.data.enterGateLog.detectionImageUrl}}),_c('v-fade-transition',[_c('IconButton',{directives:[{name:"show",rawName:"v-show",value:(hover),expression:"hover"}],staticClass:"download-overlay",attrs:{"buttonColor":"black","icon":"mdi-download"},on:{"click":function($event){_vm.$helper.download(
                        _vm.data.enterGateLog.detectionImageUrl,
                        ("export-image-" + (_vm.data.licensePlate) + "-" + (_vm.data.country) + "-" + (+new Date(_vm.data.timestamp))),
                        'image',
                        'png'
                      )}}})],1)],1)]}}])})],1),_c('v-spacer'),_c('div',{staticClass:"image mb-3"},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
                      var hover = ref.hover;
return [_c('div',{staticStyle:{"border-radius":"10px","height":"100%","width":"100%","position":"relative"}},[_c('inner-image-zoom',{key:_vm.data.id,attrs:{"hideHint":"","src":_vm.data.exitGateLog.detectionImageUrl}}),_c('IconButton',{directives:[{name:"show",rawName:"v-show",value:(hover),expression:"hover"}],staticClass:"download-overlay",attrs:{"buttonColor":"black","icon":"mdi-download"},on:{"click":function($event){_vm.$helper.download(
                      _vm.data.exitGateLog.detectionImageUrl,
                      ("export-image-" + (_vm.data.licensePlate) + "-" + (_vm.data.country) + "-" + (+new Date(_vm.data.timestamp))),
                      'image',
                      'png'
                    )}}})],1)]}}])})],1)],1),_c('div',{staticStyle:{"width":"100%"}},[_c('VehicleLogTimerange',{attrs:{"data":{
              enterTimestamp: _vm.data.enterGateLog.timestamp,
              exitTimestamp: _vm.data.exitGateLog.timestamp,
              duration: _vm.data.duration / 60,
            }}}),_c('TableListRow',{scopedSlots:_vm._u([{key:"key",fn:function(){return [_vm._v(_vm._s(_vm.$t('tools.project.violations.table.licensePlate')))]},proxy:true},{key:"value",fn:function(){return [_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var hover = ref.hover;
return [_c('v-row',{staticClass:"ma-0",attrs:{"align":"center"}},[_c('b',{staticClass:"link-value",on:{"click":function($event){return _vm.$helper.copy2Clipboard(
                        _vm.data.enterGateLog.licensePlate,
                        'Kennzeichen'
                      )}}},[_vm._v(_vm._s(_vm.data.enterGateLog.licensePlate))]),_c('v-icon',{directives:[{name:"show",rawName:"v-show",value:(hover),expression:"hover"}],staticClass:"ml-2",staticStyle:{"margin-top":"2px"},attrs:{"size":"14","color":"primary"}},[_vm._v("mdi-content-copy")])],1)]}}])})]},proxy:true}])}),_c('TableListRow',{scopedSlots:_vm._u([{key:"key",fn:function(){return [_vm._v(_vm._s(_vm.$t('tools.project.violations.table.country')))]},proxy:true},{key:"value",fn:function(){return [_c('v-row',{staticClass:"ma-0 table-key",attrs:{"align":"center"}},[_c('country-flag',{staticStyle:{"border-radius":"10px"},attrs:{"country":_vm.data.enterGateLog.country,"size":"small"}}),_c('span',{staticClass:"ml-2"},[_vm._v(_vm._s(_vm.$helper.countryName(_vm.data.enterGateLog.country)))])],1)]},proxy:true}])}),_c('TableListRow',{scopedSlots:_vm._u([{key:"key",fn:function(){return [_vm._v(_vm._s(_vm.$t('tools.project.violations.table.whitelist')))]},proxy:true},{key:"value",fn:function(){return [_c('div',{staticClass:"table-key"},[(_vm.data.whitelist != null && _vm.data.whitelist.name != null)?_c('b',[_vm._v(_vm._s(_vm.data.whitelist.name))]):_c('div',{staticClass:"grey--text",staticStyle:{"font-size":"13px","margin-top":"1px"}},[_vm._v(" "+_vm._s(_vm.$t('tools.project.violations.table.noWhitelist'))+" ")])])]},proxy:true}])}),(_vm.data.whitelist != null)?_c('TableListRow',{scopedSlots:_vm._u([{key:"key",fn:function(){return [_vm._v(_vm._s(_vm.$t('tools.project.violations.table.barrier')))]},proxy:true},{key:"value",fn:function(){return [(
                  _vm.data.whitelist != null &&
                  _vm.data.whitelist.writeToDevice == true
                )?_c('v-row',{staticClass:"ma-0 table-key success--text",staticStyle:{"font-size":"13px"},attrs:{"align":"center"}},[_c('v-icon',{staticClass:"mr-1",attrs:{"color":"success","size":"17"}},[_vm._v("mdi-check-circle")]),_c('b',[_vm._v(_vm._s(_vm.$t('tools.project.violations.table.openedBarrier')))])],1):_c('div',{staticClass:"grey--text table-key",staticStyle:{"font-size":"13px","margin-top":"1px"}},[_vm._v(" "+_vm._s(_vm.$t('tools.project.violations.table.noBarrierControl'))+" ")])]},proxy:true}],null,false,3080328579)}):_vm._e(),_c('v-divider',{staticClass:"mt-1 mb-1 mr-1 ml-1",staticStyle:{"opacity":"0.5"}}),_c('TableListRow',{scopedSlots:_vm._u([{key:"key",fn:function(){return [_vm._v(_vm._s(_vm.$t('tools.project.violations.table.enterGate')))]},proxy:true},{key:"value",fn:function(){return [(
                  (_vm.data.enterGateLog.direction == 1 ||
                    _vm.data.enterGateLog.direction == 2) &&
                  _vm.data.enterGateLog.direction != null
                )?_c('span',[(_vm.data.enterGateLog.gate != null)?_c('b',[_vm._v(_vm._s(_vm.data.enterGateLog.gate))]):_c('div',{staticClass:"grey--text table-key",staticStyle:{"font-size":"13px"}},[_vm._v(" "+_vm._s(_vm.$t('tools.project.log.gates.noName'))+" ")])]):_c('div',{staticClass:"grey--text table-key",staticStyle:{"font-size":"13px"}},[_vm._v(" "+_vm._s(_vm.$t('tools.project.log.gates.unknown'))+" ")])]},proxy:true}])}),_c('TableListRow',{scopedSlots:_vm._u([{key:"key",fn:function(){return [_vm._v(_vm._s(_vm.$t('tools.project.violations.table.exitGate')))]},proxy:true},{key:"value",fn:function(){return [(
                  (_vm.data.exitGateLog.direction == 1 ||
                    _vm.data.exitGateLog.direction == 2) &&
                  _vm.data.exitGateLog.direction != null
                )?_c('span',[(_vm.data.exitGateLog.gate != null)?_c('b',[_vm._v(_vm._s(_vm.data.exitGateLog.gate))]):_c('div',{staticClass:"grey--text table-key",staticStyle:{"font-size":"13px"}},[_vm._v(" "+_vm._s(_vm.$t('tools.project.log.gates.noName'))+" ")])]):_c('div',{staticClass:"grey--text table-key",staticStyle:{"font-size":"13px"}},[_vm._v(" "+_vm._s(_vm.$t('tools.project.log.gates.unknown'))+" ")])]},proxy:true}])}),_c('v-divider',{staticClass:"mt-1 mb-1 mr-1 ml-1",staticStyle:{"opacity":"0.5"}}),_c('TableListRow',{scopedSlots:_vm._u([{key:"key",fn:function(){return [_vm._v(_vm._s(_vm.$t('tools.project.violations.table.duration')))]},proxy:true},{key:"value",fn:function(){return [_c('span',{staticClass:"font-weight-bold primary--text",staticStyle:{"font-size":"14px"}},[_c('span',{staticClass:"mr-1"},[_vm._v("+")]),_vm._v(_vm._s(_vm.$helper.formatDuration(_vm.data.duration / 60))+" h")])]},proxy:true}])}),_c('TableListRow',{scopedSlots:_vm._u([{key:"key",fn:function(){return [_vm._v(_vm._s(_vm.$t('tools.project.violations.table.allowedTime')))]},proxy:true},{key:"value",fn:function(){return [(
                  _vm.data.whitelist != null && _vm.data.whitelist.allowedTime != null
                )?_c('div',[_c('span',{staticClass:"font-weight-bold black--text",staticStyle:{"font-size":"14px"}},[_vm._v(_vm._s(_vm.$helper.formatDuration(_vm.data.whitelist.allowedTime))+" h")]),_c('span',{staticClass:"font-weight-bold primary--text ml-2",staticStyle:{"font-size":"12px"}},[_c('span',{staticClass:"mr-1"},[_vm._v("+")]),_vm._v(_vm._s(_vm.$helper.formatDuration( _vm.data.duration / 60 - _vm.data.whitelist.allowedTime ))+" h")])]):_vm._e(),_c('span',{attrs:{"vL-else":""}},[_vm._v("-")])]},proxy:true}])})],1)],1),_c('v-dialog',{directives:[{name:"show",rawName:"v-show",value:(_vm.isEditor),expression:"isEditor"}],attrs:{"width":"400"},model:{value:(_vm.deleteVehicleLog),callback:function ($$v) {_vm.deleteVehicleLog=$$v},expression:"deleteVehicleLog"}},[_c('DeleteDialogTemplate',{attrs:{"loading":_vm.loadingArchive,"title":_vm.$t('tools.project.violations.dialog.deleteViolation.title')},on:{"delete":_vm.deleteVehicleLogEvent,"close":function($event){_vm.deleteVehicleLog = false}},scopedSlots:_vm._u([{key:"content",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t('tools.project.violations.dialog.deleteViolation.text'))+" ")]},proxy:true}])})],1)]},proxy:true},{key:"actions",fn:function(){return [_c('v-row',{staticClass:"ma-0 pb-1",attrs:{"align":"center","justify":"start"}},[_c('v-spacer'),_c('TextButton',{attrs:{"icon":"mdi-download","text":_vm.$t('tools.project.violations.table.exportButton')},on:{"click":function($event){return _vm.downloadLog()}}})],1)]},proxy:true}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }