var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticStyle:{"user-select":"none","cursor":"default","padding-top":"6px","max-height":"60px","font-size":"15px !important"},style:(_vm.dense ? 'transform: scale(.85); -webkit-transform-origin-x: 0;' : '')},[_c('div',{style:(_vm.basicLicenseStyle)},[(
        _vm.countryCode == 'D' ||
        _vm.countryCode == 'NL' ||
        _vm.countryCode == 'L' ||
        _vm.countryCode == 'A'
      )?_c('div',{staticStyle:{"height":"100%","position":"absolute","width":"15px","background-color":"#1c2596","opacity":"0.8","top":"0px","left":"0px","border-bottom-left-radius":"2px","border-top-left-radius":"2px"}},[_c('v-img',{staticStyle:{"position":"absolute","top":"4px","left":"50%","transform":"translateX(-50%)"},attrs:{"width":"10","src":require("@/assets/eu_stars.webp")}}),_c('span',{staticClass:"font-weight-bold white--text",staticStyle:{"position":"absolute","bottom":"4px","left":"50%","transform":"translateX(-50%)","font-size":"6px"}},[_vm._v(_vm._s(_vm.countryCode.toUpperCase()))])],1):_vm._e(),_c('div',{staticClass:"license-plate",class:_vm.countryCode == 'D' ||
        _vm.countryCode == 'NL' ||
        _vm.countryCode == 'L' ||
        _vm.countryCode == 'A'
          ? 'ml-3'
          : '',style:(_vm.licenseStyle)},[_c('span',{staticStyle:{"color":"rgba(0, 0, 0, 0.8)"}},[_vm._v(_vm._s(_vm.licensePlate.replace('-', ' ')))])])])])}
var staticRenderFns = []

export { render, staticRenderFns }