<template>
  <DialogTemplate
    :closeable="!loading"
    width="400"
    title="Löschen"
    v-on:close="$emit('close')"
  >
    <template v-slot:content>
      <slot name="content" />
    </template>

    <template v-slot:actions>
      <v-row class="ma-0 mt-3" align="center">
        <v-spacer />
        <TextButton
          dense
          class="ml-2"
          text="Abbrechen"
          v-on:click="$emit('close')"
        />
        <TextButton
          v-on:click="$emit('delete')"
          dense
          class="ml-2"
          text="Löschen"
          :loading="loading"
          buttonColor="primary"
        />
      </v-row>
    </template>
  </DialogTemplate>
</template>

<script>
import TextButton from '@components/button/TextButton.vue';

import DialogTemplate from '@components/dialog/DialogTemplate.vue';

export default {
  name: 'DeleteDialogTemplate',
  props: {
    title: {
      type: String,
      required: true,
    },
    loading: {
      type: Boolean,
      required: true,
    },
    close: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    DialogTemplate,
    TextButton,
  },

  data() {
    return {
      closeTrigger: false,
    };
  },

  watch: {
    close() {
      this.closeDialog();
    },
  },

  methods: {
    closeDialog() {
      this.closeTrigger = true;
      this.$nextTick(() => {
        this.closeTrigger = false;
      });
    },
  },
};
</script>
