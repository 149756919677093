<template>
    <TableListRow  style="user-select: none!important" :noKey="noKey" :noHover="noHover">
        <template v-slot:key>{{ descr }}</template>
        <template v-slot:value="{ hover }">


            <!-- <div v-if="error">
                <v-row class="ma-0" align="center">
                    <b class="mr-1"><span v-if="!slotValue">{{
                        value != null && value != 'undefined' ? value : '-'
                    }}</span>
                        <slot v-else name="value" />
                    </b>

                    <v-tooltip right open-delay="1000">
                        <template v-slot:activator="{ on, attrs }">
                            <div v-bind="attrs" v-on="on">
                                <v-icon :ripple="false" v-on:click="$emit('save', true)" size="18" color="error">
                                    mdi-alert-circle</v-icon>
                            </div>
                        </template>
                        <span>Fehler beim Aktualisieren</span>
                    </v-tooltip>
                </v-row>
            </div> -->
            <div v-if="!loading && create == true && !error" style="opacity: 1; cursor: pointer; user-select: none;" v-on:click="$emit('create')"
                >
                <b :class="hover ? 'primary--text' : ''"><span v-if="!slotValue">{{
                    value != null && value !=
                        'undefined' ? value : '-'
                }}</span>
                    <slot v-else name="value" />
                </b>
                <v-icon v-show="hover" color="primary" class="ml-1" style="margin-bottom: 2px" size="13">mdi-plus
                </v-icon>
            </div>
            <div v-else-if="!disabled && loading && !error">
                <v-row class="ma-0" align="center">

                    <b v-if="!slotValue">{{ value != null && value != 'undefined' ? value : '-' }}</b>
                    <slot v-else name="value" />

                    <v-progress-circular indeterminate width="2" size="14" color="primary"
                        :class="value != null && value.length > 0 ? 'ml-2' : ''"></v-progress-circular>
                </v-row>

            </div>
            <div v-else-if="!disabled && !loading || error == true">
                <v-edit-dialog :key="editModel" :style="`width: ${width}px!important`" @save="submitEvent"
                    @close="closeEvent" @open="openEvent" v-show="!disabled && value != null">
                    <v-tooltip :disabled="noHover" right open-delay="1000">
                        <template v-slot:activator="{ on, attrs }">
                            <span v-bind="attrs" v-on="on">
                                <b :class="hover ? 'primary--text' : ''">
                                    <span v-if="!slotValue">{{
                                        value != null && value != 'undefined' ? value : '-'
                                    }}</span>
                                    <slot v-else name="value" />
                                </b>
                                <v-icon v-if="error == true" color="error" style="margin-bottom: 3px"
                                    size="16" class="ml-2">mdi-alert-circle</v-icon>
                                <v-icon v-else color="primary" v-show="hover" class="ml-1" style="margin-bottom: 2px"
                                    size="14">{{ view == true ? 'mdi-eye' : 'mdi-pencil' }}</v-icon>
                            </span>
                        </template>
                        <span style="font-size: 12px">{{$t('helper.table.clickEditTooltip')}}</span>
                    </v-tooltip>
                    <template v-slot:input>
                        <div style="position: relative" class="pb-2 pt-1">
                            <slot name="input" />

                            <div v-show="!view">
                                <v-row class="ma-0" justify="end" align="center">

                                    <div v-show="actions == false">
                                        <div class="ml-1"
                                            style="font-size: 12px; text-align: start; background-color: transparent;  opacity: .7">
                                            {{ $t('helper.table.enterSave.1') }} <kbd
                                                style="background-color: rgba(220, 220, 220, 1); color: rgba(1, 1, 1, .8); box-shadow: none;"
                                                class="ml-1 mr-1">Enter</kbd> {{ $t('helper.table.enterSave.2') }}</div>
                                    </div>
                                    <slot v-if="actions == true" name="actions" />
                                    <v-spacer />
                                    <TextButton v-show="controls" v-on:click="saveEditData()" dense buttonColor="primary"
                                        :text="$t('helper.buttons.submit')" />
                                </v-row>
                            </div>
                        </div>
                    </template>
                </v-edit-dialog>
            </div>
            <div style="opacity: 1; cursor: default; user-select: none;" v-else>
                <b><span v-if="!slotValue">{{ value != null && value != 'undefined' ? value : '-' }}</span>
                    <slot v-else name="value" />
                </b>
                <v-icon v-show="hover" color="rgba(0, 0, 0, .4)" class="ml-1" style="margin-bottom: 2px" size="13">
                    mdi-lock</v-icon>
            </div>
        </template>
    </TableListRow>
</template>


<script>
import TableListRow from './TableListRow.vue';
import TextButton from '../button/TextButton.vue';
//   import TextInput from "../text/TextInput.vue";

export default {
    components: {
        TableListRow,
        TextButton
    },

    props: {
        controls: {
            type: Boolean,
            default: false
        },
        noKey: {
            type: Boolean,
            default: false
        },
        noHover: {
            type: Boolean,
            default: false
        },
        slotValue: {
            type: Boolean,
            default: false
        },
        view: {
            type: Boolean,
            default: false
        },

        create: {
            type: Boolean,
            default: false
        },

        listener: {
            type: Boolean,
            default: false
        },
        width: {
            type: Number,
            default: 300
        },
        descr: {
            type: String,
            required: true
        },

        value: {
            default: null
        },

        disabled: {
            type: Boolean,
        },
        loading: {
            type: Boolean
        },
        error: {
            type: Boolean
        },
        actions: {
            type: Boolean,
            default: false
        }
    },

    created() {
        document.addEventListener('keydown', (eventHandle) => {

            if (eventHandle.key == 'Enter' && (this.listener == true || this.view == true) && this.editDialogStatus == true) {
                this.$emit('save')
                this.editModel = !this.editModel;
            }

        })
    },
    data() {
        return {
            editModel: false,
            editDialogStatus: false,

        }
    },

    methods: {
        saveEditData() {
            console.log("SA")
            const keydown = new KeyboardEvent('keydown', {
                bubbles: true, cancelable: false, key: "Enter"
            });
            document.body.dispatchEvent(keydown);

            
        },
        openEvent() {
            this.$emit('open', true)
            this.editDialogStatus = true;
        },

        closeEvent() {
            this.$emit('close')
            this.editDialogStatus = false;
        },

        submitEvent() {

            if (this.editDialogStatus == true) {
                this.$emit('save');
                this.closeEvent()
            }

        }
    }
}
</script>