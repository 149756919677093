<template>
  <div
    v-if="data != null"
    class="mb-3 mr-1"
    style="
      background-color: rgba(220, 220, 220, 0.5);
      border-radius: 10px;
      padding-top: 1px;
      padding-bottom: 3px;
    "
  >
    <ul class="bar">
      <li>{{ $helper.formatDate(data.enterTimestamp) }}</li>
      <li>
        {{ $helper.formatDate(data.exitTimestamp) }}
        <span
          style="font-size: 13px"
          class="primary--text font-weight-bold ml-2"
        >
          + {{ $helper.formatDuration(data.duration) }} h
        </span>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'VehicleLogTimerange',
  props: {
    data: {
      required: true,
    },
  },
};
</script>

<style scoped>
.bar {
  list-style: none;
  font-size: 15px;
  user-select: none !important;
}
.bar > li {
  position: relative;
}
.bar > li:before {
  content: '\25CF';
  margin-right: 10px;
  font-size: 20px;
}
.bar > li:after {
  position: absolute;
  left: 0;
  top: 0;
  content: '';
  border-left: 2px solid black;
  margin-left: 5px;
  height: 100%;
}
.bar > li:first-of-type:after {
  top: 50%;
}
.bar > li:last-of-type:after {
  top: -50%;
}
</style>
