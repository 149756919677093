<template>
  <div
    style="
      user-select: none;
      cursor: default;
      padding-top: 6px;
      max-height: 60px;
      font-size: 15px !important;
    "
    :style="
      dense ? 'transform: scale(.85); -webkit-transform-origin-x: 0;' : ''
    "
  >
    <div :style="basicLicenseStyle">
      <div
        v-if="
          countryCode == 'D' ||
          countryCode == 'NL' ||
          countryCode == 'L' ||
          countryCode == 'A'
        "
        style="
          height: 100%;
          position: absolute;
          width: 15px;
          background-color: #1c2596;
          opacity: 0.8;
          top: 0px;
          left: 0px;
          border-bottom-left-radius: 2px;
          border-top-left-radius: 2px;
        "
      >
        <v-img
          style="
            position: absolute;
            top: 4px;
            left: 50%;
            transform: translateX(-50%);
          "
          width="10"
          src="@/assets/eu_stars.webp"
        />
        <span
          style="
            position: absolute;
            bottom: 4px;
            left: 50%;
            transform: translateX(-50%);
            font-size: 6px;
          "
          class="font-weight-bold white--text"
          >{{ countryCode.toUpperCase() }}</span
        >
      </div>

      <div
        :class="
          countryCode == 'D' ||
          countryCode == 'NL' ||
          countryCode == 'L' ||
          countryCode == 'A'
            ? 'ml-3'
            : ''
        "
        class="license-plate"
        :style="licenseStyle"
      >
        <span style="color: rgba(0, 0, 0, 0.8)">{{
          licensePlate.replace('-', ' ')
        }}</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'LicensePlateTemplate',
  props: {
    country: {
      required: true,
    },
    dense: {
      type: Boolean,
      default: false,
    },
    licensePlate: {
      type: String,
      required: true,
    },
  },
  computed: {
    countryCode() {
      switch (this.country.toUpperCase()) {
        case 'DE':
          return 'D';
        case 'NL':
          return 'NL';
        case 'LU':
          return 'L';
        case 'AT':
          return 'A';
        case 'CH':
          return 'CH';

        default:
          return '';
      }
    },

    basicLicenseStyle() {
      var basicStyleHandle =
        'position: relative; display: inline-block; border: 2px solid black; overflow: hidden; padding-left: 10px; padding-right: 5px; border-radius: 8px; max-width: 300px; height:34px;';
      if (this.country.toUpperCase() == 'F' || this.country == 'I') {
        return basicStyleHandle + 'padding-right: 20px';
      }
      if (this.country.toUpperCase() == 'NL' || this.country == 'L') {
        return basicStyleHandle + 'background-color: #FED100;';
      } else {
        return basicStyleHandle + 'background-color: rgba(240, 240, 240, 1);';
      }
    },

    licenseStyle() {
      if (this.country == 'B') {
        return 'color: #94393F!important';
      } else {
        return 'color: black';
      }
    },
  },
};
</script>

<style scoped>
@font-face {
  font-family: 'FE-Font';
  src: url('~@/assets/fonts/gatereport/fe_font-webfont.eot')
      format('embedded-opentype'),
    url('~@/assets/fonts/gatereport/fe_font-webfont.woff') format('woff'),
    url('~@/assets/fonts/gatereport/fe_font-webfont.ttf') format('truetype'),
    url('~@/assets/fonts/gatereport/fe_font-webfont.svg') format('svg');

  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
.license-plate {
  font-family: 'FE-Font' !important;
  letter-spacing: 2.5px;
  font-size: 20px;
  line-height: 30px;
  height: 50px;
}
</style>
