<template>
  <Dialog
    :close="closeTrigger"
    :closeable="!loadingFiles"
    v-on:close="closeDialog()"
    width="550"
    :title="
      !violations
        ? $t('tools.project.log.dialog.download.title.log')
        : $t('tools.project.log.dialog.download.title.violations')
    "
  >
    <template v-slot:button="{on, attrs}">
      <div v-bind="attrs" v-on="on">
        <IconButton icon="mdi-file-download" />
      </div>
    </template>

    <template v-slot:content>
      <div class="pb-2">
        <DateRangeInput
          :style="
            loadingFiles
              ? 'pointer-events: none!important; cursor: default!important; opacity: .5'
              : ''
          "
          :loading="false"
          :disabled="false"
          v-on:update="dateRange = $event"
          :dateRange="dateRange"
        />

        <!-- <v-divider class="ml-2 mr-2"/> -->
        <v-row
          class="ma-0 mt-2 ml-2 mr-2"
          :style="
            loadingFiles
              ? 'pointer-events: none!important; cursor: default!important; opacity: .5'
              : ''
          "
          align="center"
        >
          <div
            :class="exportFiles.table ? ' primary--text' : ''"
            :style="
              exportFiles.table
                ? 'background-color: rgba(210, 210, 210, 1)'
                : ''
            "
            v-on:click="exportFiles.table = !exportFiles.table"
            class="file-selector"
          >
            <v-row class="ma-0" align="center">
              <v-icon
                :color="exportFiles.table ? 'primary' : ''"
                class="mr-1"
                size="20"
              >
                mdi-microsoft-excel</v-icon
              >
              <span class="font-weight-bold">{{
                $t('tools.project.log.dialog.download.type.spreadsheet')
              }}</span>
              <span class="ml-1">(.xlsx)</span>
            </v-row>
          </div>
          <v-spacer />
          <div
            :class="exportFiles.protocol ? 'primary--text' : ''"
            :style="
              exportFiles.protocol
                ? 'background-color: rgba(210, 210, 210, 1)'
                : ''
            "
            v-on:click="exportFiles.protocol = !exportFiles.protocol"
            class="file-selector ml-4"
          >
            <v-row class="ma-0" align="center">
              <v-icon
                :color="exportFiles.protocol ? 'primary' : ''"
                class="mr-1"
                size="20"
              >
                mdi-file-pdf-box</v-icon
              >
              <span class="font-weight-bold">{{
                $t('tools.project.log.dialog.download.type.protocol')
              }}</span>
              <span class="ml-1">(.pdf)</span>
            </v-row>
          </div>
          <v-spacer />

          <div
            :class="exportFiles.json ? 'primary--text' : ''"
            :style="
              exportFiles.json ? 'background-color: rgba(210, 210, 210, 1)' : ''
            "
            v-on:click="exportFiles.json = !exportFiles.json"
            class="file-selector ml-4"
          >
            <v-row class="ma-0" align="center">
              <v-icon
                :color="exportFiles.json ? 'primary' : ''"
                class="mr-1"
                size="20"
                >mdi-code-braces-box
              </v-icon>
              <span class="font-weight-bold"
                >{{ $t('tools.project.log.dialog.download.type.json') }}
              </span>
              <span class="ml-1">(.json)</span>
            </v-row>
          </div>
        </v-row>

        <div
          class="mb-0 mt-0 pt-1 pr-2 pl-2"
          :style="
            loadingFiles
              ? 'pointer-events: none!important; cursor: default!important; opacity: .5'
              : ''
          "
          style="
            position: relative;
            padding-left: 3px;
            padding-right: 3px;
            width: 100%;
          "
        >
          <div
            class="mt-2"
            :style="
              expandFilterSettings
                ? `height: ${
                    violations == true ? 255 : 330
                  }px; transition: all 150ms linear;`
                : 'height: 45px; transition: all 150ms linear'
            "
            style="
              background-color: #f8f8f8;
              border: 1px solid #f0f0f0;
              border-radius: 10px;
              overflow: hidden !important;
            "
          >
            <div class="filter-settings">
              <v-row
                class="ma-0 ml-4"
                style="padding-top: 4px; padding-right: 5px"
                align="center"
              >
                <b style="font-size: 16px">{{
                  $t('tools.project.log.dialog.download.filterTitle')
                }}</b>
                <v-spacer />
                <IconButton
                  class="mr-2"
                  :style="
                    expandFilterSettings ? 'transform: rotate(180deg)' : ''
                  "
                  v-on:click="expandFilterSettings = !expandFilterSettings"
                  icon="mdi-chevron-down"
                />
              </v-row>
            </div>

            <div style="width: calc(100% - 30px); margin-left: 15px">
              <TextInput
                class="mt-2 mb-2"
                :value="licensePlateSearch"
                v-on:change="licensePlateSearch = $event"
                :placeholder="
                  $t('tools.project.log.dialog.download.filterSearch')
                "
              />
              <FilterSelection
                :violations="violations"
                :key="updateData"
                :filter="filterHandle"
                :whitelistData="whitelistData"
                v-on:update="filterHandle = $event"
              />
            </div>
          </div>
          <div
            :key="includeImages"
            :style="
              exportFiles.protocol != true
                ? 'opacity: .3; pointer-events: none!important'
                : ''
            "
          >
            <Checkbox
              :src="includeImages"
              v-on:update="includeImages = $event"
              :text="$t('tools.project.log.dialog.download.embedImages')"
            />
            <v-alert
              v-if="includeImages == true"
              text
              filled
              dense
              style="font-size: 14px; color: rgba(0, 0, 0, 0.5) !important"
              color="grey"
              class="mt-3"
            >
              {{ $t('tools.project.log.dialog.download.hintImages') }}
            </v-alert>
          </div>
        </div>
      </div>
    </template>

    <template v-slot:actions>
      <v-row class="ma-0 mt-3" align="center">
        <div v-if="noDataResp == true">
          <v-row class="ma-0 ml-2" align="center">
            <v-icon class="mr-2" color="grey" size="18">mdi-information</v-icon>
            <b class="grey--text" style="font-size: 14px">{{
              $t('tools.project.log.dialog.download.noDataFound')
            }}</b>
          </v-row>
        </div>
        <v-spacer />

        <TextButton
          v-on:click="exportProtocol()"
          :disabled="
            dateRange == null ||
            (exportFiles.protocol == false &&
              exportFiles.table == false &&
              exportFiles.json == false) ||
            noDataResp == true
          "
          class="ml-2"
          :loading="loadingFiles"
          :text="$t('tools.project.log.dialog.download.button.export')"
          buttonColor="primary"
        />
      </v-row>
    </template>
  </Dialog>
</template>

<script>
import TextButton from '@components/button/TextButton.vue';

import Dialog from '@components/dialog/Dialog.vue';
import DateRangeInput from '@components/text/DateRangeInput.vue';
import FilterSelection from '../FilterSelection.vue';
import IconButton from '@components/button/IconButton.vue';
import Checkbox from '../../../../helper/text/Checkbox.vue';
import TextInput from '../../../../../../ui-components/src/components/text/TextInput.vue';

export default {
  props: {
    whitelistData: {
      required: true,
      default: null,
    },
    headers: {
      required: true,
    },

    violations: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    TextButton,
    Dialog,
    DateRangeInput,
    FilterSelection,
    IconButton,
    Checkbox,
    TextInput,
  },

  data() {
    return {
      licensePlateSearch: '',
      includeImages: false,
      noDataResp: false,
      expandFilterSettings: false,
      updateData: false,
      sortDescending: false,
      filterHandle: null,
      whitelistIdFilter: null,
      loadingFiles: false,
      closeTrigger: false,
      dateRange: null,
      exportFiles: {
        table: false,
        protocol: false,
        json: false,
      },
    };
  },
  watch: {
    licensePlateSearch() {
      this.noDataResp = false;
    },
    exportFiles: {
      handler() {
        if (this.exportFiles.protocol == false) {
          this.includeImages = false;
        }

        this.noDataResp = false;
      },
      immediate: true,
      deep: true,
    },
    filter: {
      handler() {
        if (this.filter != null) {
          this.filterHandle = this.filter;
          this.updateData = !this.updateData;
          this.noDataResp = false;
        }
      },
      immediate: true,
      deep: true,
    },

    dateRange() {
      this.noDataResp = false;
    },

    filterHandle: {
      handler() {
        this.noDataResp = false;
      },
      deep: true,
    },
  },

  methods: {
    closeDialog() {
      this.loadingFiles = false;

      this.closeTrigger = true;
      this.$nextTick(() => {
        this.closeTrigger = false;
        this.dateRange = null;
        this.exportFiles = {
          table: false,
          protocol: false,
          json: false,
        };
        this.licensePlateSearch = '';
      });
      setTimeout(() => {
        this.expandFilterSettings = false;
        this.filterHandle = this.filter;
      }, 250);
    },

    created() {
      this.noDataResp = false;
    },

    async exportProtocol() {
      try {
        this.loadingFiles = true;
        this.noDataResp = false;

        var resp;

        const promiseResp = await Promise.all([
          this.$api.gatereport.whitelists(this.$route.params.projectId),
          this.$api.gatereport.projectGates(this.$route.params.projectId),
        ]);

        if (promiseResp[0].success != true || promiseResp[1].success != true)
          throw new Error();

        var gateListResp = promiseResp[1];
        var whitelistResp = promiseResp[0];

        console.log('WL', whitelistResp);

        if (gateListResp.data == null) gateListResp.data = {};

        if (this.violations == true) {
          resp = await this.$api.gatereport.vehicleLogs(
            this.$route.params.projectId,
            {
              page: 1,
              items: this.includeImages ? 1000 : 100000,
              search: this.licensePlateSearch,
              ...this.filterHandle,
              dateRange: this.dateRange,
            },
            true,
            this.includeImages,
          );
        } else {
          resp = await this.$api.gatereport.gatelog(
            this.$route.params.projectId,
            {
              page: 1,
              items: this.includeImages ? 1000 : 100000,
              search: this.licensePlateSearch,
              ...this.filterHandle,
              dateRange: this.dateRange,
            },
            false,
            this.includeImages,
          );
        }
        // resp.data = []

        if (resp.success == true) {
          if (
            resp.data == null ||
            resp.data.results == null ||
            resp.data.results.length == 0
          ) {
            this.$toast.info(
              'Keine passenden Daten zu den Filterkriterien gefunden.',
            );
            this.noDataResp = true;
            this.loadingFiles = false;
            return;
          }

          if (this.exportFiles.json == true) {
            this.$helper.downloadJSON(
              resp.data,
              `export-${
                this.violations == true ? 'report' : 'log'
              }-${+new Date()}`,
            );
          }

          if (this.exportFiles.table == true) {
            if (this.violations == true) {
              this.$helper.downloadXLSX(
                'export-violations-report-',
                null,
                [
                  {
                    value: 'id',
                    text: this.$t('tools.project.violations.table.id'),
                  },
                  {
                    value: 'barrier',
                    text: this.$t('tools.project.violations.table.barrier'),
                  },
                  {
                    value: 'licensePlate',
                    text: this.$t(
                      'tools.project.violations.table.licensePlate',
                    ),
                    width: 15,
                  },
                  {
                    value: 'whitelistId',
                    text: this.$t('tools.project.violations.table.whitelistId'),
                    width: 22,
                  },
                  {
                    value: 'enterGate',
                    text: this.$t('tools.project.violations.table.enterGate'),
                    width: 25,
                  },
                  {
                    value: 'enterTimestamp',
                    text: this.$t(
                      'tools.project.violations.table.exitTimestamp',
                    ),
                    date: true,
                    width: 22,
                  },
                  {
                    value: 'exitGate',
                    text: this.$t('tools.project.violations.table.exitGate'),
                    width: 25,
                  },
                  {
                    value: 'exitTimestamp',
                    text: this.$t(
                      'tools.project.violations.table.enterTimestamp',
                    ),
                    date: true,
                    width: 22,
                  },
                  {
                    value: 'durationData',
                    text: this.$t('tools.project.violations.table.duration'),
                    width: 17,
                  },
                ],
                resp.data.results.map((entry) => {
                  const whitelistHandle = whitelistResp.data.whitelists.reduce(
                    (a, b) => (a.id == entry.whitelistId ? a : b),
                  );

                  return {
                    barrier:
                      entry.whitelist != null &&
                      entry.whitelist.writeToDevice != null
                        ? entry.whitelist.writeToDevice
                        : null,
                    enterGate:
                      gateListResp.data[entry.enterGateLog.deviceId] || '-',
                    exitGate:
                      gateListResp.data[entry.exitGateLog.deviceId] || '-',
                    whitelistName:
                      entry.whitelistId != null &&
                      whitelistHandle != null &&
                      whitelistHandle != false &&
                      whitelistHandle.id == entry.whitelistId
                        ? whitelistHandle.name
                        : null,
                    licensePlate: entry.enterGateLog.licensePlate,
                    durationData: parseInt(entry.duration / 60),
                    enterTimestamp: entry.enterGateLog.timestamp || null,
                    exitTimestamp: entry.exitGateLog.timestamp || null,

                    ...entry,
                  };
                }),
              );
            } else {
              this.$helper.downloadXLSX(
                'export-log-report-',
                null,
                [
                  {value: 'id', text: this.$t('tools.project.log.table.id')},
                  {
                    value: 'licensePlate',
                    text: this.$t('tools.project.log.table.licensePlate'),
                    width: 15,
                  },
                  {
                    value: 'gate',
                    text: this.$t('tools.project.log.table.gate'),
                    width: 25,
                  },
                  {
                    value: 'timestamp',
                    text: this.$t('tools.project.log.table.date'),
                    date: true,
                    width: 22,
                  },
                  {
                    value: 'whitelistId',
                    text: this.$t('tools.project.log.table.whitelistId'),
                    width: 25,
                  },
                ],
                resp.data.results.map((entry) => {
                  return {
                    ...entry,
                    gate: gateListResp.data[entry.deviceId] || '-',
                  };
                }),
              );
            }
          }

          if (this.exportFiles.protocol == true) {
            var result;

            if (this.violations == true) {
              const violationData = resp.data.results.map((entry) => {
                const whitelistHandle = whitelistResp.data.whitelists.reduce(
                  (a, b) => (a.id == entry.whitelistId ? a : b),
                );

                var entryData = entry;
                entryData.enterGateLog = {
                  ...entryData.enterGateLog,
                  gate: gateListResp.data[entry.enterGateLog.deviceId] || '-',
                };
                entryData.exitGateLog = {
                  ...entryData.exitGateLog,
                  gate: gateListResp.data[entry.exitGateLog.deviceId] || '-',
                };
                return {
                  ...entryData,
                  whitelist:
                    entry.whitelistId != null &&
                    whitelistHandle != null &&
                    whitelistHandle != false
                      ? whitelistHandle
                      : '-',
                };
              });

              result = await this.$pdf.createBundleVehicleLog(
                {
                  data: violationData,
                  projectName: this.$store.getters.project.general.name,
                },
                this.includeImages,
              );
            } else {
              const gatelogData = resp.data.results.map((entry) => {
                const whitelistHandle = whitelistResp.data.whitelists.reduce(
                  (a, b) => (a.id == entry.whitelistId ? a : b),
                );

                return {
                  ...entry,
                  gate: gateListResp.data[entry.deviceId],
                  whitelist:
                    entry.whitelistId != null &&
                    whitelistHandle != null &&
                    whitelistHandle != false
                      ? whitelistHandle
                      : '-',
                };
              });
              result = await this.$pdf.createBundleGateLog(
                {
                  data: gatelogData,
                  projectName: this.$store.getters.project.general.name,
                },
                this.includeImages,
              );
            }

            if (result != true) {
              throw new Error();
            } else {
              setTimeout(() => {
                this.licensePlateSearch = '';
                this.dateRange = null;
                this.exportFiles = {
                  table: false,
                  protocol: false,
                  json: false,
                };
                this.includeImages = false;
                this.expandFilterSettings = false;
                this.filterHandle = null;
                this.updateData = !this.updateData;
              }, 300);
            }
          }
        } else {
          throw new Error();
        }

        this.loadingFiles = false;
        this.closeDialog();
      } catch (error) {
        console.log(error);
        this.$toast.error(
          this.$t('tools.project.log.toasts.error.downloadReportData'),
        );
        this.loadingFiles = false;
        return;
      }
    },
  },
};
</script>

<style>
.vc-title {
  pointer-events: none !important;
}

.vc-container {
  border: none !important;
  font-family: 'Lato', sans-serif !important;
}

.vc-arrow {
  border-radius: 50%;
}

.file-selector {
  background-color: rgba(220, 220, 220, 0.7);
  width: fit-content;
  height: fit-content;
  padding: 5px 12px 5px 10px;
  border-radius: 8px;
  user-select: none;
  cursor: pointer;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.8);
}

.file-selector:hover {
  background-color: rgba(220, 220, 220, 1);
}

.descending-button {
  background-color: rgba(210, 210, 210, 1);
  border-radius: 50%;
}

.filter-settings {
  height: 45px;
  width: 100%;
  background-color: #f0f0f0;
}
</style>
