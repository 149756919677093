<template>
  <ProjectPageTemplate
  style="position: relative; height: 100%; width: 100%;"

    :hideHeader="true"
    :title="$t(`${$route.meta.i18n}.title`)"
    :loading="false"
    :isSettings="true"
    :error="false"
  >
    <template v-slot:content>
      <div id="table">
        <TableTemplate
          v-on:close="filterTempHandle = null"
          v-on:search="searchVehicleLogData"
          v-on:link="linkClickedEvent"
          v-on:update="updateReportData()"
          v-on:page="tablePageEvent"
          :hideHeader="true"
          :serverSearch="true"
          :search="filterHandle.search"
          :resetPage="resetTablePage"
          :searchable="true"
          :error="errorRequest"
          :group="false"
          :searchText="$t('tools.project.violations.searchText')"
          :actions="false"
          :dataTotal="serverTotalLogs"
          :loading="loadingData"
          :headers="headers"
          :src="logs"
          :title="$t(`${$route.meta.i18n}.title`)"
        >
          <template v-slot:actions>
            <v-row
              class="ma-0"
              style="max-height: 40px; overflow: hidden"
              align="start"
            >
              <DateRangeInput
                :dense="true"
                :filter="true"
                :loading="false"
                :dateRange="filterHandle.dateRange"
                :key="updatedFilter"
                v-on:update="updateDateRange"
              />

              <FilterInput
                v-on:reset="removeFilterEvent"
                v-on:update="applyDataFilterEvent"
                v-on:close="cancelFilterEvent"
                :active="activeFilter"
                :filter="activeFilter"
              >
                <template v-slot:input>
                  <FilterSelection
                    class="filter-selection"
                    :key="updateFilter"
                    violations
                    :filter="filterTempHandle"
                    :whitelistData="whitelistData"
                    :projectGateData="[]"
                    v-on:update="filterTempHandle = $event"
                  />
                </template>
              </FilterInput>
            </v-row>

            <v-spacer />

            <DownloadVehicleLogs
              class="mr-2 ml-3"
              :headers="headers"
              violations
              :whitelistData="whitelistData"
              :projectGateData="[]"
            />
          </template>

          <!-- table column: country -->
          <template v-slot:plate="{data}">
            <div class="expand">
              <LicensePlateTemplate
                style="cursor: pointer !important"
                dense
                :country="data.enterGateLog.country"
                :licensePlate="data.enterGateLog.licensePlate"
              />
            </div>
          </template>
          <!-- table column: country -->
          <!-- displaying only country flag -->
          <template v-slot:country="{data}">
            <country-flag
              class="country-flag"
              :country="data.enterGateLog.country"
              size="normal"
            />
          </template>

          <template v-slot:duration="{data}">
            <span class="primary--text font-weight-bold" style="font-size: 13px"
              >+ {{ $helper.formatDuration(data.duration / 60) }} h</span
            >
          </template>
        </TableTemplate>
      </div>

      <v-dialog
        v-show="gatelogDialog.data != null"
        v-model="gatelogDialog.status"
        width="600"
        style="overflow: hidden"
      >
        <VehicleLogInformation
          v-on:update="filterReportData()"
          :data="gatelogDialog.data"
          v-on:close="gatelogDialog.status = false"
        />
      </v-dialog>
    </template>
  </ProjectPageTemplate>
</template>

<script>
import ProjectPageTemplate from '@/components/tools/project/ProjectPageTemplate.vue';
import TableTemplate from '../../admin/TableTemplate.vue';
import CountryFlag from 'vue-country-flag';

import VehicleLogInformation from '../../../../components/tools/project/gatereport/dialog/VehicleLogInformation.vue';

import DownloadVehicleLogs from '../../../../components/tools/project/gatereport/dialog/DownloadVehicleLogs.vue';
import DateRangeInput from '@components/text/DateRangeInput.vue';
import FilterInput from '@components/text/FilterInput.vue';

import FilterSelection from '../../../../components/tools/project/gatereport/FilterSelection.vue';
import LicensePlateTemplate from '../../../../components/tools/project/gatereport/LicensePlateTemplate.vue';

export default {
  name: 'ProjectViewViolations',
  components: {
    ProjectPageTemplate,
    TableTemplate,
    CountryFlag,
    VehicleLogInformation,
    DownloadVehicleLogs,
    DateRangeInput,
    FilterInput,
    FilterSelection,
    LicensePlateTemplate,
  },

  data() {
    return {
      projectGateList: null,
      errorRequest: false,
      serverTotalLogs: 0,
      updatedFilter: false,
      addLoading: false,
      addApplyToAll: false,
      addSelectList: null,
      closeEditDialog: false,
      updateFilter: false,
      filterTempHandle: null,

      resetTablePage: false,
      filterHandle: {
        dateRange: null,
        sortDescending: false,
        sortBy: 'TIMESTAMP',
        search: '',
        whitelistId: null,
      },

      gatelogDialog: {
        status: false,
        data: null,
      },
      whitelistData: null,
      loadingData: false,
      paginationSettings: {
        page: 1,
        itemsPerPage: 20,
      },
      headers: [
        {
          text: this.$t('tools.project.violations.table.id'),
          value: 'id',
          align: 'center',
          width: '140px',
          sortable: false,
          hide: false,
        },

        {
          text: this.$t('tools.project.violations.table.licensePlate'),
          align: 'start',
          slot: 'plate',
          link: true,
          value: 'licensePlate',
          width: '255px',
          bold: true,
          sortable: false,
        },

        {
          text: this.$t('tools.project.violations.table.enterTimestamp'),
          value: 'enterGateLog.timestamp',
          date: true,
          datetime: true,
          width: '200px',
          sortable: false,
        },
        {
          text: this.$t('tools.project.violations.table.exitTimestamp'),
          value: 'exitGateLog.timestamp',
          date: true,
          datetime: true,
          width: '200px',
          sortable: false,
        },
        {
          text: this.$t('tools.project.violations.table.duration'),
          value: 'duration',
          slot: 'duration',
          sortable: false,
          width: '100px',
          align: 'center',
        },
        {
          text: this.$t('tools.project.violations.table.whitelist'),
          value: 'whitelist',
          sortable: false,
          link: true,
        },
        // { text: 'Fahrzeuggruppe', value: 'whitelist', slot: 'whitelist', link: true, sortable: false },
        {
          text: this.$t('tools.project.violations.table.country'),
          value: 'country',
          slot: 'country',
          align: 'center',
          width: '90px',
          sortable: false,
        },
      ],

      logs: null,
    };
  },

  computed: {
    activeFilter() {
      return (
        this.filterHandle.sortDescending != false ||
        this.filterHandle.sortBy != 'TIMESTAMP' ||
        this.filterHandle.whitelistId != null
      );
    },
  },

  created() {
    if (this.$route.query != null && this.$route.query.whitelistId != null) {
      this.filterTempHandle = this.filterHandle;
      this.filterTempHandle.whitelistId = this.$route.query.whitelistId;
    } else if (this.$route.query.plate != null) {
      this.filterHandle.search = this.$route.query.plate;
      // return;
    }

    this.updateReportData();
  },

  watch: {
    filterHandle() {
      // console.log("HERERe")
      this.filterTempHandle = this.filterHandle;
      // this.filterReportData()
    },

    paginationSettings(newVal, oldVal) {
      // console.log(oldVal, newVal)
      if (oldVal == false) return;
      return this.filterReportData();
    },
  },

  methods: {
    searchVehicleLogData(eventHandle) {
      this.filterHandle.search = eventHandle;

      this.filterTempHandle = this.filterHandle;

      if (
        this.$route.query != null &&
        this.$route.query.plate != null &&
        this.$route.query.plate != eventHandle
      )
        this.$router.replace({query: {}});
      this.filterReportData();
    },
    tablePageEvent(eventHandle) {
      this.paginationSettings = eventHandle;
    },
    updateDateRange(eventHandle) {
      // console.log("UPDATED DATE RANGE: ", eventHandle)
      if (eventHandle == null) {
        this.filterHandle.dateRange = null;
      } else {
        this.filterHandle.dateRange = eventHandle;
      }

      this.resetTablePage = !this.resetTablePage;
      this.filterTempHandle = this.filterHandle;
      this.updatedFilter = !this.updatedFilter;

      this.filterReportData();
    },

    addVehicleWhitelist() {
      this.addLoading = true;
      setTimeout(() => {
        this.closeEditDialog = !this.closeEditDialog;
        this.addLoading = false;

        this.updateReportData();
        this.$toast.info('Kennzeichn wurde zur Fahrzeuggruppe hinzugefügt.');

        // console.log('moin')
      }, 2000);
    },

    cancelFilterEvent() {
      this.filterTempHandle = this.filterHandle;
      setTimeout(() => {
        this.updateFilter = !this.updateFilter;
      }, 250);
    },
    applyDataFilterEvent() {
      if (this.filterTempHandle == null) return;
      if (
        this.filterHandle.sortBy != this.filterTempHandle.sortBy ||
        this.filterHandle.sortDescending !=
          this.filterTempHandle.sortDescending ||
        this.filterHandle.whitelistId != this.filterTempHandle.whitelistId
      ) {
        this.filterHandle = this.filterTempHandle;

        this.resetTablePage = !this.resetTablePage;
        this.updateFilter = !this.updateFilter;

        this.filterReportData();
      }
    },
    removeFilterEvent(eventHandle) {
      if (eventHandle != null) return;
      this.filterHandle.sortBy = 'TIMESTAMP';
      this.filterHandle.whitelistId = null;
      this.filterHandle.sortDescending = false;

      this.filterTempHandle = this.filterHandle;

      if (this.$route.query != null && this.$route.query.id != null)
        this.$router.replace({query: {}});

      this.filterReportData();
    },

    linkClickedEvent(eventHandle) {
      if (eventHandle.col == 'licensePlate') {
        this.gatelogDialog.data = eventHandle.data;
        console.log('HERERE', eventHandle);
        if (eventHandle.data.whitelistId != null) {
          this.gatelogDialog.data = {
            ...this.gatelogDialog.data,
            whitelist: this.whitelistData.reduce((a, b) =>
              a.id == eventHandle.data.whitelistId ? a : b,
            ),
          };
        }
        this.gatelogDialog.status = true;
      } else if (eventHandle.col == 'whitelist') {
        return this.$router.push({
          name: 'project_whitelist',
          query: {id: eventHandle.data.whitelistId},
        });
      }
    },
    async filterReportData() {
      if (
        this.paginationSettings == null ||
        this.paginationSettings.page == null ||
        this.paginationSettings.itemsPerPage == null
      )
        return;
      this.loadingData = true;

      const gatelogResp = await this.$api.gatereport.vehicleLogs(
        this.$route.params.projectId,
        {
          ...this.filterHandle,
          page: this.paginationSettings.page,
          items: this.paginationSettings.itemsPerPage,
        },
        true,
      );

      if (gatelogResp.success == true) {
        this.serverTotalLogs = gatelogResp.data.pagination.total;

        if (this.serverTotalLogs == 0) {
          this.logs = [];
          this.serverTotalLogs = 0;
        } else {
          this.logs = gatelogResp.data.results.map((log) => {
            var sortedData;
            if (log.whitelistId != null && this.whitelistData != null) {
              const whitelistHandle = this.whitelistData.reduce((a, b) =>
                a.id == log.whitelistId ? a : b,
              );
              sortedData = {
                ...log,
                whitelist:
                  whitelistHandle != null ? whitelistHandle.name : null,
                whitelistData: whitelistHandle,
                barrier:
                  whitelistHandle != null
                    ? whitelistHandle.writeToDevice
                    : false,
                isMatched: log.vehicleLog != null,
              };
            } else sortedData = {...log, isMatched: log.vehicleLog != null};

            console.log(this.projectGateList, log.deviceId);
            if (this.projectGateList != null) {
              sortedData.enterGateLog = {
                ...sortedData.enterGateLog,
                gate: this.projectGateList[log.enterGateLog.deviceId],
              };
              sortedData.exitGateLog = {
                ...sortedData.exitGateLog,
                gate: this.projectGateList[log.exitGateLog.deviceId],
              };
            }

            return sortedData;
          });

          console.log(this.logs);
        }

        // this.logs = gatelogResp.data.results || [];
      } else {
        this.errorRequest = true;
        this.logs = [];
        this.$toast.error(
          this.$t('tools.project.violations.toasts.error.failedLoadingData'),
        );
      }

      this.loadingData = false;
    },

    async updateReportData() {
      // console.log("h")
      this.loadingData = true;

      const resp = await Promise.all([
        this.$api.gatereport.whitelists(this.$route.params.projectId),
        this.$api.gatereport.projectGates(this.$route.params.projectId),
      ]);

      console.log(resp);
      if (resp[0].success == true) {
        this.whitelistData = resp[0].data.whitelists;

        if (resp[1].success == true) {
          this.projectGateList = resp[1].data;
        }
      } else {
        this.$toast.error(
          this.$t('tools.project.violations.toasts.error.failedLoadingData'),
        );
        this.loadingData = false;
        return;
      }

      await this.filterReportData();

      // console.log("LOADING OFF")

      this.loadingData = false;
    },
  },
};
</script>

<style scoped>
.dashboard-area {
  height: 100vh;
  width: 100%;
  overflow-y: auto;
  overflow-x: hidden !important;
}

.filter-selection {
  width: 400px;
}
.country-flag {
  border-radius: 10px;
  transform: scale(0.45);
}

.expand {
  overflow: hidden;
}

.expand:hover {
  transform: scale(
    1.03
  ) !important; /* (150% zoom - Note: if the zoom is too large, it will go outside of the viewport) */

  transition: transform 50ms linear 50ms;
  transform-origin: center center;
  animation: normal;
}
</style>
